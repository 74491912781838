import React, { Component } from "react";
import { Button, Box } from "grommet";
// import { Ionicons } from "@expo/vector-icons";
// import { LinearGradient } from "expo-linear-gradient";
import CursosUser from "../../components/cursosUser";
import constantes from "../../utils/constantes";
import ModalDNI from "../../components/ModalDNI";

export default class InicioSMART extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      drawerOpen: false,
      modalOpen: false
    };
  }

  async componentDidMount() {
    this.setState({ pepe: await localStorage.getItem("user") });
    const user = await localStorage.getItem("user");
    this.setState({
      modalOpen: user.dni === "" ? true : false,
      user
    });
  }
  closeControlPanel = () => {
    this._drawer.close();
  };

  openControlPanel = () => {
    this._drawer.open();
  };

  nif = dni => {
    let validChars = "TRWAGMYFPDXBNJZSQVHLCKET";
    let nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    let nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    let str = dni.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

    let nie = str
      .replace(/^[X]/, "0")
      .replace(/^[Y]/, "1")
      .replace(/^[Z]/, "2");

    let letter = str.substr(-1);
    let charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (validChars.charAt(charIndex) === letter) return true;

    return false;
  };

  postDNI = async dni => {
    this.setState({
      refreshing: true
    });
    const resutl = this.nif(dni);
    const headers = await localStorage.getItem("jwt");
    if (resutl) {
      fetch(constantes("URL_API") + "/users/" + this.state.user._id, {
        method: "PUT",
        headers,
        body: JSON.stringify({
          dni
        })
      })
        .then(data => data.json())
        .then(async response => {
          if (response.statusCode) {
            alert("Error en el servidor, intentelo de nuevo");
            this.setState({
              refreshing: false
            });
          } else {
            let user = JSON.parse(await localStorage.getItem("user"));
            user.dni = dni;
            localStorage.setItem("user", JSON.stringify(user));
            this.setState({
              refreshing: false,
              modalOpen: false
            });
          }
        });
    } else {
      alert("AVISO", "DNI Incorrecto.", [
        {
          text: "Ok",
          style: "cancel"
        }
      ]);
    }
  };

  render() {

    return (
      <Box className="Patata">
        <ModalDNI modalOpen={this.state.modalOpen} postDNI={this.postDNI} />
        <Box>
          <CursosUser
            navigation={this.props.navigation}
            closeDrawer={this.closeControlPanel}
          />
        </Box>
      </Box>
    );
  }
}
