import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { Box, TextInput, Button, Image, Keyboard, Text } from "grommet";
import { Hide, View } from "grommet-icons";
import constantes from "../../utils/constantes";

const ForgotPassword = ({ match }) => {
  const jwt = match.params.jwt;
  const [form, setForm] = useState({
    pass: "",
    repitPass: ""
  });
  const [goodmsg, setGoodmsg] = useState("");
  const [erromsg, setErromsg] = useState("");
  const [reveal, setReveal] = React.useState(false);
  const [reveal2, setReveal2] = React.useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const forgotPassword = () => {
    if (form.pass.trim() === "" || form.repitPass.trim() === "") {
      setErromsg("Debes completar los campos de contraseña.");
      setForm({
        pass: "",
        repitPass: ""
      });
    } else if (form.pass !== form.repitPass) {
      setErromsg("Las contraseñas deben coincidir.");
      setForm({
        pass: "",
        repitPass: ""
      });
    } else {
      const body = {
        code: jwt,
        password: form.pass,
        passwordConfirmation: form.repitPass
      };
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json"
      };
      fetch(constantes("URL_API") + "/auth/reset-password", {
        method: "POST",
        body: JSON.stringify(body),
        headers
      })
        .then(data => data.json())
        .then(data => {
          if (
            data.message &&
            data.message.includes("Incorrect code provided")
          ) {
            setErromsg("En enlace es incorrecto o a expirado.");
            setForm({
              pass: "",
              repitPass: ""
            });
          } else if (data.jwt) {
            setGoodmsg("Contraseña cambiada con exito.");
            setForm({
              pass: "",
              repitPass: ""
            });
          }
        })
        .catch(err => {
          setErromsg(err.message);
          setForm({
            pass: "",
            repitPass: ""
          });
        });
    }
  };

  return (
    <Keyboard onEnter={() => forgotPassword()}>
      <Box
        background={constantes("loginbackground")}
        justify="center"
        align="center"
        height="calc(100vh)"
      >
        <Box>
          <Box
            pad="small"
            gap="small"
            background="light-1"
            round="small"
            style={{
              boxShadow: "0px 15px 30px -15px rgba(0,0,0,0.35)",
              position: "relative"
            }}
            align="center"
          >
            <Box width="medium" height="90px" margin="medium">
              <Image
                fit="contain"
                alt="hub7s logo"
                src="https://training-solvia.s3.eu-west-1.amazonaws.com/fd52aa28c4e64a57ab27bca7ef28140f.png"
              />
            </Box>
            <Box>
              <Box
                width="medium"
                direction="row"
                align="center"
                round="small"
                border
                style={{ margin: 5 }}
              >
                <label style={{ marginLeft: 15 }} htmlFor="Contraseña">
                  Contraseña:
                </label>
                <TextInput
                  plain
                  placeholder="Contraseña"
                  id="Contraseña"
                  name="Contraseña"
                  type={reveal ? "text" : "password"}
                  value={form.pass}
                  onChange={event =>
                    setForm({ ...form, pass: event.target.value })
                  }
                />
                <Button
                  icon={
                    reveal ? (
                      <Hide size="medium" aria-label="Ocultar contraseña" />
                    ) : (
                      <View size="medium" aria-label="Mostrar contraseña" />
                    )
                  }
                  onClick={() => setReveal(!reveal)}
                />
              </Box>
              <Box>
                <Box
                  width="medium"
                  direction="row"
                  align="center"
                  round="small"
                  border
                  style={{ margin: 5 }}
                >
                  <label
                    style={{ marginLeft: 15 }}
                    htmlFor="Repetir Contraseña"
                  >
                    Repetir Contraseña:
                  </label>
                  <TextInput
                    plain
                    placeholder="Repetir Contraseña"
                    id="Repetir Contraseña"
                    name="Repetir Contraseña"
                    type={reveal2 ? "text" : "password"}
                    value={form.repitPass}
                    onChange={event =>
                      setForm({ ...form, repitPass: event.target.value })
                    }
                  />
                  <Button
                    icon={
                      reveal2 ? (
                        <Hide size="medium" aria-label="Ocultar contraseña" />
                      ) : (
                        <View size="medium" aria-label="Mostrar contraseña" />
                      )
                    }
                    onClick={() => setReveal2(!reveal2)}
                  />
                </Box>
              </Box>
            </Box>

            <Button
              label={"Cambiar Contraseña"}
              color="gray"
              onClick={() => forgotPassword()}
              style={{ marginBottom: 20 }}
            />
          </Box>
        </Box>
        {goodmsg !== "" && (
          <Box
            animation="slideUp"
            background="#675f77"
            style={{ borderRadius: 15 }}
            height="40px"
            justify="center"
            role="alert"
            pad="10px"
            onClick={() => {
              setGoodmsg("");
            }}
          >
            <Text>{goodmsg}</Text>
          </Box>
        )}
        {erromsg !== "" && (
          <Box
            animation="slideUp"
            background="#C82333"
            style={{ borderRadius: 15 }}
            height="40px"
            justify="center"
            role="alert"
            pad="10px"
            onClick={() => {
              setErromsg("");
            }}
          >
            <Text>{erromsg}</Text>
          </Box>
        )}
      </Box>
    </Keyboard>
  );
};

export default withRouter(ForgotPassword);
