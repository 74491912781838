import React from "react";
import { Box, Heading } from "grommet";
import { socketConnect } from "socket.io-react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import io from "socket.io-client";

import constantes from "../../../utils/constantes";
import WebinarChat from "./webinarChat";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import withLocalStorage from "../../../utils/localStore";
import WebinarView from "../../../components/WebinarView";
import RecordingsWebinar from "../../../components/RecordingsWebinar";
import WebinarRecordings from "../../../components/webinarRecord";

const SocketEndpoint = constantes("URL_API");
const SCREEN_WIDTH = window.innerWidth;
const SCREEN_HEIGHT = window.innerHeight;

const socket = io(SocketEndpoint, {
  transports: ["websocket"]
});

const Webinar = ({ history, id, jwt }) => {
  let urlparams = new URLSearchParams(history.location.search);
  const webinar = urlparams.get("webinar");
  const { loading, error, data, refetch } = useQuery(queries.GetWebinarData, {
    variables: {
      id: webinar
    }
  });

  const [createComentario] = useMutation(mutations.createComentario);

  socket.on("notification", () => {
    refetch();
  });

  if (loading) return null;
  if (error) {
    console.log(error);
    return null;
  }

  return (
    <Box style={{ marginTop: 70, maxHeight: SCREEN_HEIGHT - 70 }}>
      <Box
        fill="horizontal"
        align="center"
        pad="small"
        justify="center"
        background={constantes('maincolor')}
      >
        <Heading textAlign="center" color="white" level={3} a11yTitle={data.webinar.name} tabIndex={0}>
          {data.webinar.name}
        </Heading>
      </Box>

      <Box direction="row-responsive">
        {data.webinar.state !== "finalizado" ? (
          <Box basis="65%" background="black" height="large" flex={"grow"}>
            <WebinarView webinar={webinar} env={"pre"} jwt={jwt} user={id} />
          </Box>
        ) : (
            <Box basis="65%" background="black" height="large" flex={"grow"}>
              {/* <WebinarRecordings
                webinar={data.webinar}
                jwt={jwt}
              /> */}
              <RecordingsWebinar webinar={webinar} />
            </Box>
          )}
        <Box basis="35%">
          <WebinarChat
            refetch={() => refetch()}
            comentarios={data.comentarios}
            webinar={data.webinar}
            createComentario={comentario => createComentario(comentario)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default socketConnect(withLocalStorage(withRouter(Webinar)));
