import React, { useState } from "react";
import { Box, TextInput, Button, Heading, Keyboard } from "grommet";
import * as GrommetIcons from "grommet-icons";
import _ from 'lodash';
import constantes from "../utils/constantes";

const EditProfile = ({ data, updateUser, changePass, setChangePass, setSuccessMsg, setErromsg }) => {
  const [form, setForm] = useState({
    id: data.user._id,
    username: data.user.username,
    email: data.user.email,
    name: data.user.name,
    surname: data.user.surname,
    dni: data.user.dni,
    actualPass: "",
    firstPass: "",
    secondPass: ""
  });

  const checkPersonalForm = () => {
    let res = true;
    const { email: emailInit, name: nameInit, surname: surnameInit, dni: dniInit } = data.user;
    const { email: emailForm, name: nameForm, surname: surnameForm, dni: dniForm } = form;
    if (emailInit === emailForm && nameInit === nameForm && surnameInit === surnameForm && dniInit === dniForm) {
      res = false;
    }
    return res;
  }

  const launchMutations = (variables) => {
    updateUser({ variables }).then(data => {
      if (_.get(data, "data.updateUser.user")) {
        setErromsg("")
        setChangePass(false)
        setSuccessMsg("Cambios realizados con exito.")
      }

    }).catch(err => {
      setErromsg("Error. Vuelve a intentarlo.")
    })
  }

  const withPass = (variables) => {
    fetch(`${constantes("URL_API")}/auth/local`,
      {
        method: "POST",
        body: JSON.stringify({
          identifier: form.username,
          password: form.actualPass
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    ).then(data => data.json())
      .then(response => {
        const statusCode = _.get(response, "statusCode");
        if (statusCode) {
          setErromsg(statusCode === 400 ? "Error. Contraseña Incorrecta" : "Error. Vuelve a intentarlo.");
        } else {
          _.set(variables, 'password', form.firstPass)
          launchMutations(variables)
        }
      })
  }

  const editProfile = () => {
    let variables = {
      id: form.id,
      name: form.name,
      surname: form.surname,
      dni: form.dni,
      email: form.email
    }

    if (changePass) {
      if (form.actualPass.trim() === "" || form.firstPass.trim() === "" || form.secondPass.trim() === "") {
        setErromsg("Error. Debe rellenar todas las contraseñas o cancele el cambio de contraseña.")
      } else if (form.firstPass === form.secondPass) {
        withPass(variables)
      } else {
        setErromsg("Error. Las contraseñas nuevas no coinciden.")
      }
    } else if (checkPersonalForm()) {
      launchMutations(variables)
    }
  };

  return (
    <Keyboard onEnter={() => editProfile()}>
      <Box
        align="center"
        style={{
          width: "100%"
        }}
        pad="small"
        gap="small"
      >
        <Box
          align="center"
          style={{
            width: "60%",
            backgroundColor: "white",
            borderRadius: 25,
            padding: 25
          }}
          pad="small"
          gap="small"
        >
          <Heading margin="none">Editar Perfil</Heading>
          <Box
            width="medium"
            direction="row"
            align="center"
            round="small"
            border
            style={{ margin: 5 }}
          >
            <label style={{ marginLeft: 15 }} htmlFor="username">
              Usuario:
                  </label>
            <TextInput
              tabIndex={0}
              plain
              disabled
              id={"username"}
              name={"username"}
              aria-label={"Usuario"}
              placeholder={"Usuario"}
              value={form.username}
            />
          </Box>

          <Box
            width="medium"
            direction="row"
            align="center"
            round="small"
            border
            style={{ margin: 5 }}
          >
            <label style={{ marginLeft: 15 }} htmlFor="email">
              Email:
                  </label>
            <TextInput
              plain
              tabIndex={0}
              id={"email"}
              name={"email"}
              aria-label={"Email"}
              placeholder={"Email"}
              onChange={event => setForm({ ...form, email: event.target.value })}
              value={form.email}
            />
          </Box>

          <Box
            width="medium"
            direction="row"
            align="center"
            round="small"
            border
            style={{ margin: 5 }}
          >
            <label style={{ marginLeft: 15 }} htmlFor="name">
              Nombre:
                  </label>
            <TextInput
              plain
              tabIndex={0}
              id={"name"}
              name={"name"}
              aria-label={"Nombre"}
              placeholder={"Nombre"}
              onChange={event => setForm({ ...form, name: event.target.value })}
              value={form.name}
            />
          </Box>

          <Box
            width="medium"
            direction="row"
            align="center"
            round="small"
            border
            style={{ margin: 5 }}
          >
            <label style={{ marginLeft: 15 }} htmlFor="surname">
              Apellidos:
                  </label>
            <TextInput
              plain
              tabIndex={0}
              id={"surname"}
              name={"surname"}
              aria-label={"Apellidos"}
              placeholder={"Apellidos"}
              onChange={event => setForm({ ...form, surname: event.target.value })}
              value={form.surname}
            />
          </Box>

          <Box
            width="medium"
            direction="row"
            align="center"
            round="small"
            border
            style={{ margin: 5 }}
          >
            <label style={{ marginLeft: 15 }} htmlFor="dni">
              DNI:
                  </label>
            <TextInput
              plain
              tabIndex={0}
              id={"dni"}
              name={"dni"}
              aria-label={"DNI"}
              placeholder={"DNI"}
              onChange={event => setForm({ ...form, dni: event.target.value })}
              value={form.dni}
            />
          </Box>

          <Button
            label={changePass ? "Cancelar" : "Cambiar Contraseña"}
            color="gray"
            onClick={() => {
              setChangePass(!changePass);
            }}
            icon={
              changePass ? (
                <GrommetIcons.Close
                  color={"gray"}
                  aria-hidden="true"
                  aria-label="Icono Cancelar"
                />
              ) : (
                  <GrommetIcons.Lock
                    color={"gray"}
                    aria-hidden="true"
                    aria-label="Icono Cambiar contraseña"
                  />
                )
            }
          />

          {changePass && <Box animation="fadeIn">
            <Box
              width="medium"
              direction="row"
              align="center"
              round="small"
              border
              style={{ margin: 5 }}
            >
              <label style={{ marginLeft: 15 }} htmlFor="actualPass">
                Contraseña Actual:
                  </label>
              <TextInput
                plain
                tabIndex={0}
                id={"actualPass"}
                name={"actualPass"}
                aria-label={"Contraseña Actual"}
                placeholder={"Contraseña Actual"}
                onChange={event =>
                  setForm({ ...form, actualPass: event.target.value })
                }
                value={form.actualPass}
              />
            </Box>
            <Box
              width="medium"
              direction="row"
              align="center"
              round="small"
              border
              style={{ margin: 5 }}
            >
              <label style={{ marginLeft: 15 }} htmlFor="firstPass">
                Nueva Contraseña:
                  </label>
              <TextInput
                plain
                tabIndex={0}
                id={"firstPass"}
                name={"firstPass"}
                aria-label={"Nueva Contraseña"}
                placeholder={"Nueva Contraseña"}
                onChange={event =>
                  setForm({ ...form, firstPass: event.target.value })
                }
                value={form.firstPass}
              />
            </Box>
            <Box
              width="medium"
              direction="row"
              align="center"
              round="small"
              border
              style={{ margin: 5 }}
            >
              <label style={{ marginLeft: 15 }} htmlFor="secondPass">
                Repita Contraseña:
                  </label>
              <TextInput
                plain
                tabIndex={0}
                id={"secondPass"}
                name={"secondPass"}
                aria-label={"Repita Contraseña"}
                placeholder={"Repita Contraseña"}
                onChange={event =>
                  setForm({ ...form, secondPass: event.target.value })
                }
                value={form.secondPass}
              />
            </Box>
          </Box>}

          <Box>
            <Button
              onClick={() => editProfile()}
              color="gray"
              label="Editar perfil"
              reverse
              icon={
                <GrommetIcons.Save
                  color={"grey"}
                  aria-hidden="true"
                  aria-label="Icono Editar perfil"
                />
              }
            />
          </Box>
        </Box>
      </Box>
    </Keyboard>
  );
};

export default EditProfile;
