import React, { useState, useEffect } from "react";
// import { View, Text, Image, ScrollView, TextInput } from "react-native";
import { Box, Text, Image, TextInput, Heading } from "grommet";
import constantes from "../utils/constantes";

const RespuestaComponent = ({
  titulo,
  post,
  avancecurso,
  setNexReady,
  respuesta,
  setRespuesta
}) => {
  const [status, setStatus] = useState("");

  useEffect(() => {
    const aEffect = avancecurso.find(x => x._id === post._id);

    setRespuesta(aEffect.respuesta === undefined ? "" : aEffect.respuesta);
    setStatus(aEffect.status);

    if (aEffect.respuesta !== undefined) {
      setNexReady(true);
    }
  }, [avancecurso]);
  console.log("He entrado en respuesta")
  return (
    <Box
      style={{
        elevation: 1,
        paddingBottom: 30,
        backgroundColor: "white",
        marginBottom: 50,
        flex: 1
      }}
    >



      <Box
        className="PatataFrita"
        height="small"
        // width="medium"
        style={{
          backgroundColor: "#ddd",
          flex: 2,
        }}
      >
        <Image
          src={post.image !== null ? post.image.url : constantes("placeholder")}
          alt={post.image ? post.image.alt : "Imagen del contenido tipo Respuesta"}
          tabIndex={0}
          fit="cover"
          style={{ flex: 1, height: "200px" }}
        />

      </Box>
      <Heading level="2" tabIndex={0} aria-label={titulo}>{titulo}</Heading>
      <Text
        style={{
          // marginTop: 20,
          marginBottom: 40
        }}
        tabIndex={0}
        aria-label={post.content}
      >
        {post.content}
      </Text>
      {status === "completado" ? (
        <Box>
          <Box
            style={{
              height: 200,
              backgroundColor: "#F0F0F0",
              borderRadius: 25,
              paddingLeft: 15,
              marginBottom: 20,
              justifyContent: "center"
            }}
            tabIndex={0}
            aria-label={"Tu respuesta  es: " + respuesta}
          >
            <Text>{respuesta}</Text>
          </Box>
        </Box>
      ) : (
          <TextInput
            style={{
              height: 200,
              backgroundColor: "#F0F0F0",
              borderRadius: 25,
              paddingLeft: 15,
              marginBottom: 20
            }}
            tabIndex={0}
            aria-label={"Escribe tu respuesta"}
            placeholder={"Escribe tu respuesta"}
            onChange={event => {
              if (event.target.value.trim() === "") {
                setNexReady(false);
              } else {
                setNexReady(true);
              }
              setRespuesta(event.target.value);
            }}
            value={respuesta}
          />
        )}


    </Box>
  );
};

export default RespuestaComponent;
