import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import { Box, TextInput, Button, Image, Keyboard, Text } from "grommet";
import { Hide, View } from "grommet-icons";
import constantes from "../../utils/constantes";

const Login = ({ history }) => {
  // Declare a new state variable, which we'll call "count"
  const [username, setUser] = useState("");
  const [password, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [erromsg, setErromsg] = useState("");
  const [recoverPass, setRecoverPass] = useState(false);
  const [reveal, setReveal] = React.useState(false);

  const sendRecoveryEmail = async () => {
    const body = {
      email: email,
      url: constantes("URL_APP")
    };

    fetch(`${constantes("URL_API")}/auth/forgot-password`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())

      .then(async data => {
        if (data.statusCode === 400) {
          console.log("ehem errorsito");
          setErromsg("Email no valido");
        } else {
          setRecoverPass(false);
          alert(
            "Se ha envíado un correo electrónico a " +
              body.email +
              " para que puedas recuperar tu contraseña"
          );
        }
      })
      .catch(err => console.log(err));
  };

  const login = async () => {
    const body = {
      identifier: username,
      password
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    const data = await fetch(`${constantes("URL_API")}/auth/local`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
    })
      .then(data => data.json())
      .catch(err => console.log(err));

    if (data.error) {
      console.log(data.message);
      setErromsg(
        "El email y la contraseña no coinciden. Revísalo y vuelva a intentarlo"
      );
      // setErromsg(data.message)
    } else {
      console.log({ data });
      await localStorage.setItem("jwt", data.jwt);
      await localStorage.setItem("user", JSON.stringify(data.user));
      await localStorage.setItem("id", data.user.id);
      history.push("/");
    }
  };

  return (
    <Keyboard onEnter={() => login()}>
      <Box
        background={constantes("loginbackground")}
        justify="center"
        align="center"
        height="calc(100vh)"
        // style={{ marginTop: -70 }}
      >
        <Box>
          <Box
            //height="small"
            //width="medium"
            pad="small"
            gap="small"
            background="light-1"
            round="small"
            style={{
              boxShadow: "0px 15px 30px -15px rgba(0,0,0,0.35)",
              position: "relative"
            }}
            align="center"
          >
            <Box
              width="medium"
              height="90px"
              margin="medium"
              // style={{ marginTop: -150 }}
            >
              <Image
                fit="contain"
                alt="hub7s logo"
                src="https://training-solvia.s3.eu-west-1.amazonaws.com/6dc5472ebe644b34874963b1805108ad.png"
              />
            </Box>
            {recoverPass === false && (
              <Box>
                <Box
                  width="medium"
                  direction="row"
                  align="center"
                  round="small"
                  border
                  style={{ margin: 5 }}
                >
                  <label style={{ marginLeft: 15 }} htmlFor="Usuario">
                    Usuario:
                  </label>
                  <TextInput
                    placeholder="ejemplo@hub7s.com"
                    id="Usuario"
                    name="Usuario"
                    plain
                    value={username}
                    onChange={event => setUser(event.target.value)}
                  />
                </Box>
                <Box
                  width="medium"
                  direction="row"
                  align="center"
                  round="small"
                  border
                  style={{ margin: 5 }}
                >
                  <label style={{ marginLeft: 15 }} htmlFor="Contraseña">
                    Contraseña:
                  </label>
                  <TextInput
                    plain
                    placeholder="Contraseña"
                    id="Contraseña"
                    name="Contraseña"
                    type={reveal ? "text" : "password"}
                    value={password}
                    onChange={event => setPass(event.target.value)}
                  />
                  <Button
                    icon={
                      reveal ? (
                        <Hide size="medium" aria-label="Ocultar contraseña" />
                      ) : (
                        <View size="medium" aria-label="Mostrar contraseña" />
                      )
                    }
                    onClick={() => setReveal(!reveal)}
                    // a11yTitle={
                    //   reveal ? "Ocultar contraseña" : "Mostrar contraseña"
                    // }
                  />
                </Box>
              </Box>
            )}
            {recoverPass === true && (
              <Box
                width="medium"
                direction="row"
                align="center"
                round="small"
                border
              >
                <TextInput
                  plain
                  placeholder="Email"
                  onChange={event => setEmail(event.target.value)}
                  value={email}
                />
              </Box>
            )}

            <Button
              label={recoverPass === false ? "Acceder" : "Recuperar contraseña"}
              color="gray"
              onClick={() =>
                recoverPass === false ? login() : sendRecoveryEmail()
              }
              style={{ marginBottom: 20 }}
            />
          </Box>
          <Box style={{ cursor: "pointer", marginTop: 15 }} align="center">
            <Box align="center">
              <Text
                color="white"
                onClick={() => {
                  setEmail("");
                  setPass("");
                  setEmail("");
                  setErromsg("");
                  setRecoverPass(!recoverPass);
                }}
              >
                {recoverPass === false
                  ? "He olvidado mi contraseña"
                  : "Volver al login"}
              </Text>
            </Box>
          </Box>
        </Box>
        {erromsg !== "" && (
          <Box
            animation="slideUp"
            background="#C82333"
            style={{ borderRadius: 15 }}
            height="40px"
            justify="center"
            role="alert"
            pad="10px"
            onClick={() => setErromsg("")}
          >
            <Text>{erromsg}</Text>
          </Box>
        )}
      </Box>
    </Keyboard>
  );
};

export default withRouter(Login);
