import React, { Component } from "react";

import { Box, TextInput, Button, Paragraph } from "grommet";

class ModalDNI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dni: ""
    };
  }

  render() {
    return (
      <Box
        animation="fadeIn"
        style={{ display: this.props.modalOpen ? "visible" : "none" }}
      >
        <Box
          style={{
            backgroundColor: "rgba(0,0,0,0.7)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box
            style={{
              backgroundColor: "white",
              elevation: 10,
              borderRadius: 7,
              height: 245,
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 25,
              paddingLeft: 10,
              paddingRight: 10
            }}
          >
            <Paragraph
              style={{
                marginBottom: 25,
                textAlign: "center",
                fontSize: 16,
                marginTop: 10
              }}
              textAlign="center"
            >
              Debido a la nueva actualización, para la obtención de diplomas
              dentro de la aplicación es necesario tu NIE/DNI. Porfavor añadalo
              antes de continuar.
            </Paragraph>
            <Box
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginLeft: 10
              }}
            >
              <label style={{ marginLeft: 15 }} htmlFor="Dni">
                Dni:
              </label>
              <TextInput
                id="Dni"
                name="Dni"
                value={this.state.dni}
                a11yTitle="Dni"
                placeholder={"12345678Z"}
                onChange={event => this.setState({ dni: event.target.value })}
              />
            </Box>
            <Box
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginHorizontal: 20
              }}
            >
              <Button
                style={{
                  flex: 1,
                  height: 45,
                  backgroundColor: "#64C500",
                  borderRadius: 25,
                  elevation: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 15
                }}
                onClick={() => this.props.postDNI(this.state.dni)}
              >
                Aceptar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default ModalDNI;
