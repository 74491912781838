import React from "react";
import { Box, Text, Image, Button, Heading } from "grommet";
import constantes from "../utils/constantes";

const TestComponent = ({
  image,
  preguntas = {},
  obligatorio,
  returnrespuestas,
  titulo,
  respuestas
}) => {
  return (
    <Box flex={"grow"} pad="small">
      <Box height="small" fill="horizontal" round="small" background={image ? `url(${image.url})` : `url(${constantes("placeholder")})`}/>

      {/* //BLOQUE DE PLAYER Y TEXTO  */}

      <Box flex={"grow"}>
        {/* TITULO Y TEXTO */}

            <Heading level={3}> {preguntas.text === "null" ? "" : preguntas.text}</Heading>
            {/* ENUNCIADO*/}
            <Box gap="small" justify="center" flex={"grow"}>
              {preguntas.answers !== undefined &&
                preguntas.answers.map((d, i) => (
                  <Button
                    key={i}
                    label={d.texto}
                    primary={respuestas.some(element => element.texto === d.texto) === true ? true : false}
                    color={respuestas.some(element => element.texto === d.texto) === true ? "#A2DA34" : "gray"}
                    onClick={async () => {
                      if (
                        respuestas.some(
                          element => element._id === preguntas._id
                        ) === true
                      ) {
                        let const1 = respuestas;
                        const1[
                          const1.findIndex(
                            element => element._id === preguntas._id
                          )
                        ].value = d.value;
                        const1[
                          const1.findIndex(
                            element => element._id === preguntas._id
                          )
                        ].texto = d.texto;

                        returnrespuestas(const1);
                      } else {
                        let const2 = respuestas;
                        const2.push({
                          _id: preguntas._id,
                          value: d.value,
                          texto: d.texto
                        });
                        returnrespuestas(const2);
                      }
                      
                    }}

                    style={{
                      borderRadius:25,
                      color: respuestas.some(element => element.texto === d.texto) === true ? "white" : "gray"
                    }}
                  />
                ))}
            </Box>

          {respuestas.length === preguntas.length && (
            <Text style={{ color: "black", fontSize: 18, padding: 5 }}>
              {respuestas.filter(e => e.value === "true").length / 1 >= 0.5
                ? "¡Enhorabuena, has superado la prueba!"
                : "Has fallado la prueba. Deberías repasar los contenidos anteriores"}
            </Text>
          )}
          {obligatorio === true && (
            <Text>
              Debes contestar todas las preguntas y aprobar este ejercicio
              para poder continuar
              </Text>
          )}
        </Box>
    </Box>
  );
};

export default TestComponent;
