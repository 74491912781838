import React, { useEffect } from "react";
import { Box, Text, Image, Heading } from "grommet";
import constantes from "../utils/constantes";
import renderHTML from "react-render-html";

const NoticiaComponent = ({
  image,
  titulo,
  contenido,
  obligatorio,
  unlock
}) => {
  useEffect(() => {
    unlock();
  }, []);
  return (
    <Box>
      {image.url && image.url !== '' && < Box
        fill="horizontal"
        height="medium"
        background={
          image
            ? `url(${image.url})`
            : `url(${constantes("placeholder")})`
        }
        tabIndex={0}
        aria-label={image ? image.alt : ""}
      />}

      <Box pad="medium" margin={{ bottom: "70px" }}>
        <Heading level="2" tabIndex={0}
          aria-label={titulo}>{titulo}</Heading>

        {
          contenido !== "" && <div tabIndex={0}
            aria-label={contenido.replace(/<[^>]+>/g, '')}>
            {renderHTML(contenido)}
          </div>
        }
        {/* {obligatorio === true && (
          <Text>
            Debes visualizar el contenido completo para poder continuar
            </Text>
        )} */}
      </Box>
    </Box>
  );
};

export default NoticiaComponent;
