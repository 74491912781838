import React from "react";
// import {
//   Box,
//   Text,
//   Dimensions,
//   StyleSheet,
//   Image,
//   Button,
//   Modal
// } from "react-native";

import { Box, Text, Button, Image, Layer } from "grommet";
import constantes from "../utils/constantes";

const RetoComponent = ({
  reto = {},
  modalConfirm = false,
  toggleModal,
  createAV,
  handleNext
}) => {
  return (
    <Box>
      {modalConfirm && (
        <Layer
          onEsc={() => toggleModal()}
          onClickOutside={() => toggleModal()}
          animationType="slide"
          transparent={true}
          visible={modalConfirm}
          onRequestClose={() => toggleModal()}
        >
          <Box
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              flex: 1,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box
              style={{
                backgroundColor: "white",
                elevation: 10,
                borderRadius: 7,
                width: "100%",
                height: 425,
                padding: 10,
                justifyContent: "center"
              }}
            >
              <Image
                style={{ width: "100%", height: 200, marginBottom: 25 }}
                resizeMode={"contain"}
                src={
                  constantes("placeholder")
                }
              />
              <Text
                style={{ marginBottom: 25, textAlign: "center", fontSize: 16 }}
                textAlign="center"
              >
                Te vas a subscribir al itinerario "{reto.name}"
              </Text>
              <Box
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 50
                }}
              >
                <Text>Hora de envio de notificación 10:00</Text>
              </Box>
              <Box
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginHorizontal: 20
                }}
              >
                <Button
                  style={{
                    flex: 1,
                    height: 40,
                    backgroundColor: "gainsboro",
                    borderRadius: 25,
                    elevation: 0,
                    margin: 5
                  }}
                  onClick={() => toggleModal()}
                >
                  <Box
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text style={{ color: "white" }}>No Suscribirse</Text>
                  </Box>
                </Button>

                <Button
                  style={{
                    flex: 1,
                    height: 40,
                    backgroundColor: "#64C500",
                    borderRadius: 25,
                    elevation: 0,
                    margin: 5
                  }}
                  onClick={() => {
                    createAV();
                    handleNext();
                  }}
                >
                  <Box
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text style={{ color: "white" }}>Suscribirse</Text>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  );
};

export default RetoComponent;
