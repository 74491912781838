import React from "react";
import { Box, Heading } from "grommet";

const DocComponent = ({ media = "", titulo = "" }) => {
  return (
    <Box gap="small">
      <Heading level={2} tabIndex={0} aria-label={titulo}>
        {titulo}
      </Heading>
      <Box
        elevation="medium"
        height="500px"
        style={{
          marginBottom: 70
        }}
      >
        <p style={{ alignSelf: "center" }}>
          <a
            href={media.url}
            target="new"
            style={{ textDecoration: "none", fontSize: 14, color: "darkgrey" }}
          >
            Abrir PDF en pestaña nueva.
          </a>
        </p>
        <object
          data={media.url}
          type="application/pdf"
          width="100%"
          height="100%"
          tabIndex={0}
        />
      </Box>
    </Box>
  );
};

export default DocComponent;
