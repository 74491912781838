import gql from "graphql-tag";

export const GetUser = gql`
query($id: ID!){
  user(id:"5c06b75d4d3f2c3a2d7ac00d"){
      name
      status
      picture {
        url
      }
      avancecursos {
        _id
        avance
        name
        estao
        end_date
        init_date
        cursos {
          _id
          nombre
          descripcion
          imagen {
            url
          }
          exam {
            _id
            examenfinal
            cursos {
              _id
              avancecursos {
                _id
                user {
                  _id
                }
              }
            }
            questions {
              _id
            }
          }
        }
      }
      avancetests {
        _id
        chosenanswers
        curso{
          _id
        }
        exam{
          _id
          examenfinal
          type
          cursos{
            _id
          }
        }
      }
    avwebinars{
      _id
      state
      webinar{
        _id
        name
      }
    }
    }
  }
`

export const GetWebinarData = gql`
  query($id: ID!) {
    comentarios(limit: 0, sort: "createdAt:desc", where: { webinar: $id }) {
      _id
      comments
      publish_date
      webinar {
        _id
      }
      user {
        _id
        name
        surname
        picture {
          url
        }
      }
    }
    webinar(id: $id) {
      _id
      name
      state
      adjuntos{
        url
        name
      }
    }
  }
`;

export const GetSmartForo = gql`
  query($id: ID!) {
    comentarios(where: { cursos: $id }, sort: "createdAt:desc") {
      comments
      createdAt
      cursos {
        _id
        nombre
      }
      user {
        _id
        name
        surname
        picture {
          url
        }
      }
    }
  }
`;

export const GetAvanceCurso = gql`
  query($id: ID!) {
    avancecurso(id: $id) {
      _id
      avance
      init_date
      end_date
      cursos {
        _id
        nombre
        porcentaje_total
      }
    }
  }
`;

export const GetRecordings = gql`
query($id: ID!){
  webinar(id: $id){
    _id
    name
    recordings
  }
}
`

export const GetQuestionData = gql`
query($avancetest: ID!, $avancecurso: ID!){
  avancetest(id: $avancetest){
    _id
    chosenanswers
    state
    exam{
      _id
      name
      preguntas
      puntuacion
      interpretacion
    }
  }
  avancecurso(id:$avancecurso){
    _id
    estao
    avance
    cursos{
      _id
      nombre
    }
  }
  
}
`

export const GetUserInfo = gql`
query($id: ID!){
    user(id:$id){
      _id
      username
      email
      name
      surname
      dni
      picture{
        url
      }
    }
  }
`