import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  TextInput,
  Button,
  Paragraph,
  Heading,
  Text
} from "grommet";
import { Send, Download } from "grommet-icons";
import { withRouter } from "react-router-dom";



import withLocalStorage from "../../../utils/localStore";
import constantes from "../../../utils/constantes";
import moment from "moment";

const SCREEN_WIDTH = window.innerWidth;

const WebinarChat = ({ comentarios, webinar, createComentario, id, refetch, history }) => {
  const [comments, setComments] = useState("");
  const [toogle, setToogle] = useState("comments");
  const guardaDatos = () => {
    const variables = {
      comments,
      webinar: webinar._id,
      user: id,
      publish_date: moment()
        .utc()
        .format()
    };
    createComentario({ variables });
    setComments("");
  };

  const functionKeyDown = e => {
    if (e.keyCode === 13) {
      if (comments.trim() !== "") {
        guardaDatos();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", functionKeyDown);

    return () => {
      document.removeEventListener("keydown", functionKeyDown);
    };
  }, [comments]);

  return (
    <Box flex="grow">
      <Box
        direction="row"
        height="70px"
        style={{ maxHeight: 70 }}
        align="center"
        justify="around"
        pad="small"
        border={{ bottom: "small" }}
      >
        <Heading
          style={{ cursor: "pointer" }}
          color={toogle === "comments" ? "black" : "gainsboro"}
          onClick={() => setToogle("comments")}
          level={3}
        >
          Comentarios
        </Heading>
        <Heading
          style={{ cursor: "pointer" }}
          color={toogle === "files" ? "black" : "gainsboro"}
          onClick={() => setToogle("files")}
          level={3}
        >
          Archivos
        </Heading>
      </Box>

      {toogle === "files" && (
        <Box style={{ position: "relative" }}>
          <Box
            gap="small"
            pad="small"
            flex={true}
            style={{ marginBottom: 70 }}
            overflow="auto"
          >
            {
              webinar.adjuntos.length > 0 ? <Box>
                {
                  webinar.adjuntos.map((adjunto, i) => <Box margin="small" background="gainsboro" direction="column" key={i}>
                    <Text margin="small">{adjunto.name}</Text>
                    <Button
                      margin="small"
                      icon={<Download />}
                      label="Descargar"
                      onClick={() => window.open(adjunto.url, '_blank')}
                    />
                  </Box>)
                }
              </Box> : <Box>
                  <Text alignSelf="center" textAlign="center">
                    {"Sus videos estaran disponible pronto, actualize para intentarlo de nuevo"}
                  </Text>
                </Box>
            }
            <Button label="Actualizar"
              onClick={() => refetch()} />
          </Box>
        </Box>
      )}

      {toogle === "comments" && (
        <Box style={{ position: "relative" }} flex="grow">
          <Box
            gap="small"
            pad="small"
            flex={true}
            style={{ marginBottom: 70 }}
            overflow="auto"
          >
            {comentarios.length <= 0 ? (
              <Box style={{ alignItems: "center", justifyContent: "center" }}>
                <p>No hay mensajes, sé el primero!</p>
              </Box>
            ) : null}

            {comentarios.map((comentario, e) => {
              return (
                <Box
                  flex="shrink"
                  key={e}
                  gap="small"
                  alignSelf={comentario.user._id === id ? "end" : "start"}
                  direction={comentario.user._id === id ? "row-reverse" : "row"}
                // gap="small"
                // style={{ minHeight: 70, maxWidth: 500 }}
                >
                  <Box
                    height="70px"
                    width="70px"
                    style={{ minHeight: 70, minWidth: 70 }}
                  >
                    <Image
                      fit="cover"
                      style={{ borderRadius: 35 }}
                      src={
                        (comentario.user &&
                          comentario.user.picture &&
                          comentario.user.picture.url) ||
                        constantes("placeholder")
                      }
                    />
                  </Box>

                  <Box
                    background="#f6f6f6"
                    pad="small"
                    height="auto"
                    width={{ max: "300px" }}
                    flex="grow"
                  // style={{
                  //   maxWidth: 300,
                  //   backgroundColor: "#f6f",
                  //   padding: 10,
                  //   width: "100%"
                  // }}
                  >
                    <Text style={{ fontSize: 10, color: "gray" }}>
                      {comentario.user === null && "Usuario dado de Baja"}
                      {comentario.user && comentario.user.name}{" "}
                      {comentario.user && comentario.user.surname}
                    </Text>
                    <Box width={{ max: "300px" }} flex="grow">
                      <Text margin="none">{comentario.comments}</Text>
                    </Box>
                    <Text style={{ fontSize: 10, color: "gray" }}>
                      {" "}
                      {moment(comentario.publish_date).format(
                        "DD MMMM YYYY, h:mm:ss a"
                      )}
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </Box>

          {webinar.state !== "finalizado" && (
            <Box
              background="white"
              pad="small"
              style={{ position: "absolute", bottom: 0 }}
              width="100%"
            >
              <Box round="small" border="all" direction="row">
                <TextInput
                  plain
                  onChange={event => setComments(event.target.value)}
                  value={comments}
                  placeholder={"Escribe tu Comentario"}
                />
                <Button
                  color="gray"
                  onClick={() => {
                    if (comments !== "") {
                      guardaDatos();
                    }
                  }}
                  icon={<Send />}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default withRouter(withLocalStorage(WebinarChat));
