import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import { Box, Grommet, grommet } from "grommet";
import Menu from "./components/Menu";

import Login from "./screens/StackLogin/login";
import ForgotPassword from "./screens/StackLogin/ForgotPassword";
import Cursos from "./screens/StackSmart/iniciosmart";
import CursoIndice from "./screens/StackSmart/indexCurso";
import Contenido from "./screens/StackSmart/postSmart";
import NewSmartForo from "./screens/StackSmart/newSmart/newSmartForo";
import QuestionSmart from "./screens/StackSmart/questionSmart";
import { createBrowserHistory } from "history";
import constantes from "../src/utils/constantes";

//APOLLO
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-boost";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import EndSmart from "./screens/StackSmart/newSmart/EndSmart";
import webinar from "./screens/StackSmart/newSmart/webinar";
import QuestionSmartEncuesta from "./screens/StackSmart/newSmart/QuestionSmartEncuesta";
import EndSmartEncuesta from "./screens/StackSmart/newSmart/EndSmartEncuesta";
import MyProfile from "./screens/StackProfile/MyProfile";

const resetToken = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    // remove cached token on 401 from the server
    token = undefined;
  }
});

const myTheme = {
  global: {
    font: {
      size: constantes("defaultTextSize")
    }
  }
};

const httpLink = new HttpLink({ uri: `${constantes("URL_API")}/graphql` });

let token;

const withToken = setContext(async request => {
  // console.log(token)
  if (!token) {
    token = await localStorage.getItem("jwt");
  }
  return {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  };
});
const authFlowLink = withToken.concat(resetToken);
const link = authFlowLink.concat(httpLink);
const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("jwt") !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const history = createBrowserHistory();

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router history={history} basename={"/campus"}>
        <Grommet theme={myTheme} full>
          <Menu />

          <Box
            // fill={true}
            // overflow="hidden"
            // style={{ marginTop: 70 }}
          >
            <PrivateRoute exact path="/" component={Cursos} />
            <PrivateRoute exact path="/profile" component={MyProfile} />
            <PrivateRoute exact path="/contenido" component={Contenido} />
            <PrivateRoute exact path="/curso/indice/" component={CursoIndice} />
            <PrivateRoute exact path="/foro" component={NewSmartForo} />
            <PrivateRoute exact path="/examen" component={QuestionSmart} />
            <PrivateRoute
              exact
              path="/examenEncuesta"
              component={QuestionSmartEncuesta}
            />
            <PrivateRoute exact path="/end" component={EndSmart} />
            <PrivateRoute exact path="/webinar" component={webinar} />
            <PrivateRoute
              exact
              path="/EndSmartEncuesta"
              component={EndSmartEncuesta}
            />
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/forgotpassword/:jwt"
              component={ForgotPassword}
            />
          </Box>
        </Grommet>
      </Router>
    </ApolloProvider>
  );
};

export default App;
