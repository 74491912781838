import React, { useState, useEffect } from "react";
import { Box, Button, Image, TextInput, Text, Heading } from "grommet";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { socketConnect } from "socket.io-react";
import io from "socket.io-client";
import moment from "moment";
import constantes from "../../../utils/constantes";

import { Send } from "grommet-icons";

import withLocalStorage from "../../../utils/localStore";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

const SocketEndpoint = constantes("URL_API");
// const SCREEN_WIDTH = window.innerWidth;

const socket = io(SocketEndpoint, {
  transports: ["websocket"]
});

const NewSmartForo = props => {
  let urlparams = new URLSearchParams(props.location.search);
  const course_id = urlparams.get("id");

  const [comments, setComments] = useState("");
  // const course_id = props.location.state && props.location.state.id
  const { loading, error, data, refetch } = useQuery(queries.GetSmartForo, {
    variables: {
      id: course_id
    }
  });
  const [createComentario] = useMutation(mutations.createComentario);

  const guardaDatos = () => {
    const variables = {
      comments,
      cursos: course_id,
      user: props.id,
      publish_date: moment()
        .utc()
        .format()
    };
    // console.log(variables);
    createComentario({ variables });
    setComments("");
  };

  useEffect(
    () =>
      document.addEventListener("keydown", e => {
        if (e.keyCode === 13) {
          if (comments !== "") {
            guardaDatos();
          }
        }
      }),
    []
  );

  socket.on("notification", () => {
    refetch();
  });

  if (loading) return null;
  if (error) {
    console.log(error);
    return null;
  }

  return (
    <Box
      className="Bravas"
      align="center"
      justify="center"
      flex={true}
      style={{ margin: "0 auto", marginBottom: 80, marginTop: 90 }}
      overflow="auto"
      width="700px!important"
      a11yTitle="Foro"
      tabIndex={0}
    >
      <Heading level={2} tabIndex={0} a11yTitle={"Foro de dudas " + props.location.state.name}>Foro de dudas {props.location.state.name}</Heading>
      <Box
        className="Fritas"
        style={{ maxWidth: 700, marginBottom: 70 }}
        pad="medium"
        width={"100%"}
        gap="small"
        align="center"
        justify="center"
      >
        {!loading && data.comentarios.length <= 0 ? (
          <Heading level={3} textAlign="center" color="dark-3">
            No hay mensajes en este foro, se el primero en comentar
          </Heading>
        ) : null}
        {data.comentarios.map((comentario, e) => {
          return (
            <Box
              key={e}
              flex={"grow"}
              alignSelf={comentario.user._id === props.id ? "end" : "start"}
              direction={
                comentario.user._id === props.id ? "row-reverse" : "row"
              }
              gap="small"
              style={{ minHeight: 70, maxWidth: 500 }}
            >
              <Box height="70px" width="70px">
                <Image
                  style={{
                    borderRadius: 35
                  }}
                  alt={"Imagen de perfil del usuario " + comentario.user.name + " " + comentario.user.surname}
                  fit="cover"
                  tabIndex={0}
                  src={
                    (comentario.user &&
                      comentario.user.picture &&
                      comentario.user.picture.url) ||
                    constantes("placeholder")
                  }
                />
              </Box>

              <Box
                border="all"
                pad="small"
                round="xsmall"
                style={{
                  maxWidth: 430,
                  backgroundColor: "#f6f6f6"
                }}
              >
                <Text style={{ fontSize: 10, color: "gray" }} tabIndex={0} >
                  {comentario.user === null && "Usuario dado de Baja"}
                  {comentario.user && comentario.user.name}{" "}
                  {comentario.user && comentario.user.surname}
                  {" | "}
                  {moment(comentario.createdAt).format(
                    "DD-MM-YY [a las] hh:mm"
                  )}
                </Text>
                <Text tabIndex={0}>{comentario.comments}</Text>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box
        background="white"
        pad="small"
        style={{ position: "absolute", bottom: 0, maxWidth: 700 }}
        width="100%"
      >
        <Box round="small" border="all" direction="row">
          <TextInput
            plain
            onChange={event => setComments(event.target.value)}
            value={comments}
            placeholder={"Escribe tu Comentario"}
          />
          <Button
            color="gray"
            a11yTitle="Enviar"
            onClick={() => {
              console.log("comments");
              console.log(comments);
              if (comments !== "") {
                guardaDatos();
              }
            }}
            icon={<Send />}
          />
        </Box>

        <Button
          fill="horizontal"
          label={"Volver al inicio"}
          style={{
            height: 50,
            borderRadius: 10,
            border: "none",
            cursor: "pointer"
            // opacity: this.state.dataCurso[1] === 0 ? 0 : 1
          }}
          justify="center"
          align="center"
          color="gray"
          onClick={() => {
            props.history.push({
              pathname: "/",

            });
          }}
        />

      </Box>
    </Box >
  );
};

export default socketConnect(withLocalStorage(NewSmartForo));
