import React, { Component } from "react";
import { Box, Text, Button } from "grommet";
import moment from "moment";
import constantes from "../../utils/constantes";
import NoticiaComponent from "../../components/NoticiaComponent";
import VideoComponent from "../../components/VideoComponent";
import DocComponent from "../../components/DocComponent";
import TestComponent from "../../components/TestComponent";
import RetoComponent from "../../components/RetoComponent";
import ModalReto from "../../components/ModalReto";
import RespuestaComponent from "../../components/RespuestaComponent";

// const soundObject = new Expo.Audio.Sound();

class PostSMART extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: { type: "vacio" },
      error: null,
      title: "",
      media: [],
      dataCurso: [{ _id: "nun", entrada: ["1"] }, 0],
      error: null,
      name: "",
      visible: false,
      arrcoment: [],
      preguntaTest: {},
      arrayRespuestas: [],
      idcontenido: "",
      avancecurso: [],
      userID: "",
      nextReady: false,
      retoOk: false,
      respuesta: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.retoOk !== this.state.retoOk) {
      this.setState({ nextReady: true });
    }
    if (prevState.reto !== this.state.reto) {
      //console.log(this.state.reto)
    }
  }

  async componentDidMount() {
    // console.log(NavigationActions)

    const { navigation } = this.props;

    // const data = navigation.getParam("data", "nodata");

    let params = new URLSearchParams(this.props.location.search);
    let data = this.props.location.state;
    // params.get("id")
    // console.log(this.props.location.state[0].avance[data[1] + 1])

    // console.log(data)
    const userID = await localStorage.getItem("id");

    const hoy = new Date();
    const next = new Date(data.data[0].avance[data.data[1] + 1].fecha_inicio);
    console.log(data.data[0].avance[data.data[1]].status);

    await this.setState({
      cursoID: data.id,
      dataCurso: data.data,
      avancecurso: data.data[0].avance,
      posicion: data.data[1],
      obligatorio:
        data.data[0].avance[data.data[1]].status === "completado"
          ? false
          : data.data[0].avance[data.data[1]].obligatorio,
      arrayRespuestas: data.data[0].avance[data.data[1]].respuestas
        ? data.data[0].avance[data.data[1]].respuestas
        : [],
      userID,
      hoy,
      next
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await localStorage.getItem("jwt")}`
    };

    const post = await fetch(
      constantes("URL_API") +
      "/entradas/" +
      this.state.avancecurso[this.state.posicion]._id,
      { headers }
    ).then(data => data.json());
    // .then(response => console.log(response));

    let reto;

    if (post.type === "Reto") {
      reto = await (
        await fetch(constantes("URL_API") + "/retos/" + post.reto._id, {
          headers
        })
      ).json();
    }

    await this.setState({
      // post: { type: "vacio2" }
      post: post
      // reto: reto
    });

    if (this.state.retoOk === true) {
      this.setState({ nextReady: true });
    }
    // await console.log(post)
    // await console.log(this.state.post.type)
    if (post.type === "Test") {
      const aP = [];
      this.setState({
        preguntaTest: post.question
      });
    }
    await this.iniciaEntrada();
  }
  unlock = () => {
    this.setState({
      obligatorio: false
    });
  };

  siguienteContenido = async () => {
    let temporal = this.state.avancecurso;
    let token = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await localStorage.getItem("jwt")}`
    };
    temporal[this.state.dataCurso[1]].status = "completado";

    await fetch(
      `${constantes("URL_API")}/avancecursos/${this.state.dataCurso[2]}`,
      {
        method: "PUT",
        body: JSON.stringify({ avance: temporal }),
        headers: token
      }
    )
      .then(data => data.json())
      .then(dataJson => {
        this.setState({
          dataCurso: dataJson
        });
        // console.log(dataJson)
      });
  };

  iniciaEntrada = async () => {
    if (this.state.avancecurso[this.state.dataCurso[1]].status === null) {
      let token = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${await localStorage.getItem("jwt")}`
      };
      let temporal = this.state.avancecurso;
      // console.log("INICIAR CURSO POSICION: " + temporal)
      temporal[this.state.dataCurso[1]].status = "empezado";

      await fetch(
        `${constantes("URL_API")}/avancecursos/${this.state.dataCurso[2]}`,
        {
          method: "PUT",
          body: JSON.stringify({ avance: temporal }),
          headers: token
        }
      );
    }
  };

  iniciaTest = async () => {
    if (this.state.avancecurso[this.state.dataCurso[1] + 1].status === null) {
      let token = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${await localStorage.getItem("jwt")}`
      };
      let temporal = this.state.avancecurso;

      temporal[this.state.dataCurso[1] + 1].status = "empezado";

      fetch(
        `${constantes("URL_API")}/avancecursos/${this.state.dataCurso[2]}`,
        {
          method: "PUT",
          body: JSON.stringify({ avance: temporal }),
          headers: token
        }
      );
    }
  };

  terminaEntrada = async () => {
    if (this.state.avancecurso[this.state.dataCurso[1]].status === "empezado") {
      let token = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${await localStorage.getItem("jwt")}`
      };
      let temporal = this.state.avancecurso;

      temporal[this.state.dataCurso[1]].status = "completado";
      temporal[this.state.dataCurso[1]].respuesta = this.state.respuesta;

      fetch(
        `${constantes("URL_API")}/avancecursos/${this.state.dataCurso[2]}`,
        {
          method: "PUT",
          body: JSON.stringify({ avance: temporal }),
          headers: token
        }
      ).then(e => {
        console.log(e);
      });
    }
  };

  async goBackVideo() {
    const { navigation } = this.props;
    const userID = await localStorage.getItem("id");

    if (this.state.dataCurso[2] && userID) {
      fetch(
        constantes("URL_API") +
        "/loganalyticsentradas?entrada=" +
        this.state.dataCurso[2] +
        "&user=" +
        userID,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${await localStorage.getItem("jwt")}`
          }
        }
      )
        .then(data => data.json())
        .then(async response => {
          // console.log(constantes("URL_API")+"/loganalyticsentrada/"+response[0]._id);
          if (response.length > 0) {
            fetch(
              constantes("URL_API") +
              "/loganalyticsentradas/" +
              response[0]._id,
              {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${await localStorage.getItem("jwt")}`
                },
                body: JSON.stringify({
                  segundo_actual: this.state.advanceSlider
                })
              }
            );
          }
        });
    }
  }

  createAV = async () => {
    let notificacion_date = moment()
      .hour(10)
      .minute(0);

    if (notificacion_date.diff(moment()) < 0) {
      notificacion_date = notificacion_date.add(1, "days").format();
    }

    const body = JSON.stringify({
      notificacion_date,
      userToken: await localStorage.getItem("token"),
      user: await localStorage.getItem("id"),
      reto: this.state.post.reto._id,
      avance_int: 0,
      name: this.state.post.reto.name,
      amount: this.state.post.reto.amount,
      often: this.state.post.reto.often,
      entrada: this.state.post._id
    });

    fetch(constantes("URL_API") + "/avs", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${await localStorage.getItem("jwt")}`
      },
      body
    })
      .then(data => data.json())
      .then(response => {
        this.handleModalSubscribe();
        this.setState({
          retoOk: true
        });
      })
      .catch(err => {
        this.handleModalSubscribe();
      });
  };
  handleNext = (a = true) => {
    this.setState({ nextReady: a });
  };

  handleModalSubscribe = () => {
    this.setState({
      modalConfirm: !this.state.modalConfirm
    });
  };
  convertDate = inputFormat => {
    // function pad(s) { return (s < 10) ? '0' + s : s; }
    // var d = new Date(inputFormat);
    // return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/');
    let a = moment(inputFormat);
    let b = moment();
    return a.diff(b, "days");
  };

  render() {
    let mediatype;

    if (this.state.post.type === "Respuesta") {
      mediatype = (
        <RespuestaComponent
          post={this.state.post}
          avancecurso={this.state.avancecurso}
          titulo={this.state.post.title}
          setNexReady={nextReady => this.setState({ nextReady })}
          respuesta={this.state.respuesta}
          setRespuesta={respuesta => this.setState({ respuesta })}
        />
      );
    }

    if (this.state.post.type === "Video") {
      mediatype = (
        <VideoComponent
          handleNext={this.handleNext}
          media={this.state.post.media}
          image={this.state.post.image}
          titulo={this.state.post.title}
          contenido={
            this.state.post.content === null
              ? "<p></p>"
              : this.state.post.content
          }
          obligatorio={this.state.obligatorio}
          unlock={this.unlock}
          postID={this.state.post._id}
          dataCurso={this.state.dataCurso}
          avancecurso={this.state.avancecurso}
        />
      );
    }
    if (this.state.post.type === "Audio") {
      mediatype = (
        <VideoComponent
          handleNext={this.handleNext}
          media={this.state.post.media}
          image={this.state.post.image}
          titulo={this.state.post.title}
          contenido={this.state.post.excerpt}
          obligatorio={this.state.obligatorio}
          unlock={this.unlock}
          postID={this.state.post._id}
          dataCurso={this.state.dataCurso}
          avancecurso={this.state.avancecurso}
        />
      );
    }
    if (this.state.post.type === "Noticia") {
      mediatype = (
        <NoticiaComponent
          image={this.state.post.image}
          titulo={this.state.post.title}
          contenido={this.state.post.content}
          obligatorio={this.state.obligatorio}
          unlock={this.unlock}
        />
      );
    }
    if (this.state.post.type === "Test") {
      mediatype = (
        <TestComponent
          handleNext={this.handleNext}
          titulo={this.state.post.title}
          image={this.state.post.image}
          preguntas={this.state.preguntaTest}
          returnrespuestas={async respuestas => {
            this.setState({ arrayRespuestas: respuestas });
            let token = {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${await localStorage.getItem("jwt")}`
            };
            let temporal = this.state.avancecurso;
            // console.log("INICIAR CURSO POSICION: " + temporal)
            temporal[this.state.dataCurso[1]].respuestas = respuestas;
            console.log({ temporal });

            await fetch(
              `${constantes("URL_API")}/avancecursos/${
              this.state.dataCurso[2]
              }`,
              {
                method: "PUT",
                body: JSON.stringify({ avance: temporal }),
                headers: token
              }
            );
            temporal[this.state.dataCurso[1]].respuestas.filter(
              e => e.value === null
            ).length === 0 && this.unlock();
          }}
          respuestas={this.state.arrayRespuestas}
        />
      );
    }
    if (this.state.post.type === "Document") {
      mediatype = (
        <DocComponent
          media={this.state.post.media}
          image={this.state.post.image}
          titulo={this.state.post.title}
          contenido={this.state.post.content}
          obligatorio={this.state.obligatorio}
        />
      );
    }

    if (this.state.post.type === "Reto") {
      mediatype = (
        <Box>
          <ModalReto
            handleNext={this.handleNext}
            createAV={this.createAV}
            reto={this.state.post.reto}
            modalConfirm={this.state.modalConfirm}
            toggleModal={this.handleModalSubscribe}
          />
          <RetoComponent
            handleNext={this.handleNext}
            retoOk={this.state.retoOk}
            media={this.state.post.reto}
            userID={this.state.userID}
            image={this.state.post.image}
            titulo={this.state.post.title}
            post={this.state.post}
            excerpt={this.state.post.excerpt}
            obligatorio={true}
            toggleModal={this.handleModalSubscribe}
            unlock={this.unlock}
          />
        </Box>
      );
    }

    return (
      <Box
        align="center"
        style={{ marginBottom: 90 }}
        flex={true}
        overflow="auto"
        tabIndex={-1}
      >
        <Box
          style={{ maxWidth: 700, marginTop: 100 }}
          fill="horizontal"
          tabIndex={0}
          aria-label={"Contenido tipo " + this.state.post.type}
        >
          {mediatype}
        </Box>

        <Box
          style={{
            background: "linear-gradient(rgba(256,256,256,.9), white)",
            maxWidth: 700,
            position: "absolute",
            bottom: 0
          }}
          gap="medium"
          fill="horizontal"
          direction="row"
          pad="small"
          tabIndex={0}
          aria-label="Bloque Navegación"
        >
          {
            <Button
              fill="horizontal"
              label={this.state.dataCurso[1] === 0 ? "Salir" : "Anterior"}
              style={{
                height: 50,
                borderRadius: 10
              }}
              color="gray"
              disabled={this.state.dataCurso[1] === 0 ? true : false}
              onClick={() => {
                this.state.dataCurso[1] === 0
                  ? this.props.history.push("cursos")
                  : this.props.history.push({
                    pathname: "/contenido",
                    state: {
                      id: this.state.cursoID,
                      data: [
                        this.state.dataCurso[0],
                        this.state.dataCurso[1] - 1,
                        this.state.dataCurso[2]
                      ]
                    }
                  });
                window.location.reload();
              }}
            ></Button>
          }

          {
            // <Box
            //   fill="horizontal"
            //   label={"Volver al índice"}

            //   justify="center"
            //   align="center"
            //   color="gray"
            // >
            <Button
              style={{
                height: 50,
                borderRadius: 10,
                border: "none",
                cursor: "pointer",
                color: "gray"
              }}
              fill="horizontal"
              onClick={() => {
                this.props.history.push({
                  pathname: "/curso/indice",
                  search: `?id=${this.state.dataCurso[2]}`
                });
              }}>
              <Text>Volver al índice</Text>
            </Button>
            // </Box>
          }

          {this.state.next > this.state.hoy === true ? (
            <Button
              label={`Activo en ${this.convertDate(this.state.next)} dias`}
              disabled={true}
              fill="horizontal"
              style={{
                height: 50,
                borderRadius: 10
              }}
            ></Button>
          ) : this.state.avancecurso.length > 1 &&
            this.state.avancecurso[
              this.state.avancecurso.findIndex(
                e => e._id === this.state.post._id
              ) + 1
            ].tipo !== "test" ? (
                <Button
                  label="Siguiente"
                  fill="horizontal"
                  primary
                  color={
                    this.state.obligatorio === false
                      ? constantes("maincolor")
                      : this.state.nextReady === true
                        ? constantes("maincolor")
                        : "gray"
                  }
                  disabled={
                    this.state.obligatorio === false
                      ? false
                      : this.state.nextReady === true
                        ? false
                        : true
                  }
                  style={{
                    color:
                      this.state.obligatorio === false
                        ? "white"
                        : this.state.nextReady === true
                          ? "white"
                          : constantes("maincolor"),
                    height: 50,
                    borderRadius: 10
                  }}
                  onClick={async () => {
                    await this.terminaEntrada();

                    this.props.history.push({
                      pathname: "/contenido",
                      state: {
                        id: this.state.cursoID,
                        data: [
                          this.state.dataCurso[0],
                          this.state.dataCurso[1] + 1,
                          this.state.dataCurso[2]
                        ]
                      }
                    });
                    window.location.reload();
                  }}
                ></Button>
              ) : (
                <Button
                  label="Test"
                  fill="horizontal"
                  primary
                  color={
                    this.state.obligatorio === false
                      ? "orange"
                      : this.state.nextReady === true
                        ? "orange"
                        : "gray"
                  }
                  disabled={
                    this.state.obligatorio === false
                      ? false
                      : this.state.nextReady === true
                        ? false
                        : true
                  }
                  style={{
                    height: 50,
                    borderRadius: 10,
                    color: "white"
                  }}
                  onClick={async () => {
                    this.terminaEntrada();
                    this.iniciaTest();
                    const myid = await localStorage.getItem("id");
                    const headers = {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${await localStorage.getItem("jwt")}`
                    };
                    const at = await (
                      await fetch(
                        constantes("URL_API") +
                        "/graphql?query=%0A%7B%0Aavancetests%7B%0A%20_id%0A%20%20chosenanswers%0A%20%20curso%7B%0A%20%20%20%20_id%0A%20%20%7D%0A%20%20user%7B%0A%20%20%20%20_id%0A%20%20%20%20name%0A%20%20%7D%20%20%0A%20%20exam%7B%0A%20%20%20%20_id%0A%20%20%20%20type%0A%20%20%20%20preguntas%0A%20%20%20%20puntuacion%0A%20%20%20%20cursos%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20avancecursos%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%20examenfinal%20%0A%20%20%7D%0A%20%20questions%7B%0A%20%20%20%20_id%0A%20%20%7D%0A%20%7D%0A%7D%0A",
                        { headers }
                      )
                    ).json();
                    const avancetest = at.data.avancetests
                      .filter(e => e.user !== null && e.curso !== null && e.exam)
                      .filter(e => {
                        return (
                          e.user._id === myid &&
                          e.exam.examenfinal === true &&
                          e.exam.cursos.some(
                            e => e._id === this.state.dataCurso[0].cursos._id
                          ) &&
                          e.curso._id === this.state.dataCurso[0].cursos._id
                        );
                      })[0];
                    if (avancetest.exam.type === "encuesta") {
                      console.log(avancetest);
                      this.props.history.push({
                        pathname: "/examenEncuesta",
                        state: {
                          id: this.state.cursoID,
                          data: [avancetest._id, 0, this.state.dataCurso[2]]
                        }
                      });
                    } else {
                      this.props.history.push({
                        pathname: "/examen",
                        state: {
                          id: this.state.cursoID,
                          data: [avancetest, 0, this.state.dataCurso[2]]
                        }
                      });
                    }
                  }}
                ></Button>
              )}
        </Box>
      </Box>
    );
  }
}
export default PostSMART;
