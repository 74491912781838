import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { Previous, Next } from "grommet-icons";
import { Box, Text, Button, Heading, Meter } from "grommet";
import { useQuery, useMutation } from "@apollo/react-hooks";
import constantes from "../../../utils/constantes";

import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import withLocalStorage from "../../../utils/localStore";

const Cuerpo = ({
  pregunta,
  respuestas,
  markedAnswer,
  setMarkedAnswer,
  pointer,
  chosenanswers,
  state,
  localProgress
}) => {
  const markedAnswerKey = Object.keys(markedAnswer)[0];

  return (
    <Box>
      {/* ENUNCIADO*/}
      <Heading level={2}>{pregunta}</Heading>

      <Box gap="small">
        {respuestas.map((d, i) => {
          return (
            <Button
              fill="horizontal"
              key={i}
              label={Object.keys(d)[0]}
              style={{
                height: "auto",
                minHeight: 50,
                borderRadius: 25,
                color: !state
                  ? markedAnswerKey === Object.keys(d)[0]
                    ? "white"
                    : "grey"
                  : Object.keys(chosenanswers[pointer])[0] === Object.keys(d)[0]
                  ? "white"
                  : "grey"
              }}
              primary={
                !state
                  ? markedAnswerKey === Object.keys(d)[0]
                    ? true
                    : false
                  : Object.keys(chosenanswers[pointer])[0] === Object.keys(d)[0]
                  ? true
                  : false
              }
              onClick={async () => {
                if (!state) {
                  localProgress(d);
                }
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const QuestionSmartEncuesta = ({ history, id }) => {
  const state = history.location.state;
  const avancecurso = state.data[2];
  const avancetest = state.data[0];

  const [markedAnswer, setMarkedAnswer] = useState({ "": "" });
  const [progressData, setProgressData] = useState(0);
  const [pointer, setPointer] = useState(state.data[1]);

  const { loading, error, data, refetch } = useQuery(queries.GetQuestionData, {
    variables: {
      avancecurso,
      avancetest
    }
  });

  useEffect(() => {
    if (data) {
      if (data.avancetest.state !== "completado") {
        setPointer(data.avancetest.chosenanswers.length);
      }
      setProgressData(
        (data.avancetest.chosenanswers.length * 100) /
          data.avancetest.exam.preguntas.length
      );
    }
  }, [data]);

  const [updateAvancetest] = useMutation(mutations.updateAvancetest, {
    refetchQueries: [
      {
        query: queries.GetQuestionData,
        variables: {
          avancecurso,
          avancetest
        }
      }
    ]
  });

  const localProgress = d => {
    setMarkedAnswer(d);
    setProgressData(
      ((data.avancetest.chosenanswers.length + 1) * 100) /
        data.avancetest.exam.preguntas.length
    );
  };

  if (loading) return null;
  if (error) {
    console.log(error);
    return null;
  }

  const previous = () => {
    if (
      data.avancecurso.estao !== "activo" &&
      data.avancecurso.estao !== "iniciado"
    ) {
      pointer === 0
        ? history.push({
            pathname: "/curso/indice",
            search: `?id=${avancecurso}`
          })
        : setPointer(pointer - 1);
    } else {
      const newchose = data.avancetest.chosenanswers;
      newchose.pop();
      updateAvancetest({
        variables: {
          id: avancetest,
          chosenanswers: newchose
        }
      }).then(async () => {
        await refetch();
        if (data.avancetest.state !== "completado") {
          setPointer(data.avancetest.chosenanswers.length);
        } else {
          setPointer(pointer + 1);
        }

        setProgressData(
          (data.avancetest.chosenanswers.length * 100) /
            data.avancetest.exam.preguntas.length
        );
        setMarkedAnswer({ "": "" });
      });
    }
  };

  const next = () => {
    if (
      data.avancecurso.estao !== "activo" &&
      data.avancecurso.estao !== "iniciado"
    ) {
      pointer + 1 === data.avancetest.chosenanswers.length
        ? history.push({
            pathname: "/EndSmartEncuesta",
            search: "?avancecurso=" + avancecurso + "&avancetest=" + avancetest
          })
        : setPointer(pointer + 1);
    } else {
      let newchose = data.avancetest.chosenanswers;
      newchose[pointer] = markedAnswer;
      updateAvancetest({
        variables: {
          id: avancetest,
          chosenanswers: newchose
        }
      }).then(async () => {
        await refetch();
        if (data.avancetest.state !== "completado") {
          setPointer(data.avancetest.chosenanswers.length);
        } else {
          setPointer(pointer + 1);
        }
        setProgressData(
          (data.avancetest.chosenanswers.length * 100) /
            data.avancetest.exam.preguntas.length
        );
        setMarkedAnswer({ "": "" });
      });
    }
  };

  const end = () => {
    let newchose = data.avancetest.chosenanswers;
    newchose[pointer] = markedAnswer;
    updateAvancetest({
      variables: {
        id: avancetest,
        chosenanswers: newchose,
        state: "completado"
      }
    });
  };

  return (
    <Box flex="grow" align="center">
      <Box
        fill="horizontal"
        background={constantes("maincolor")}
        height="30px"
      />
      {data.avancetest.state !== "completado" && (
        <Meter
          type="bar"
          size="large"
          thickness="medium"
          round={true}
          background="light-3"
          style={{ marginTop: 70 }}
          values={[
            {
              value: progressData,
              color: "#A2DA34"
            }
          ]}
        />
      )}

      <Box justify="center" style={{ maxWidth: 700, minHeight: 450 }}>
        <Cuerpo
          pregunta={data.avancetest.exam.preguntas[pointer]}
          respuestas={data.avancetest.exam.puntuacion}
          markedAnswer={markedAnswer}
          setMarkedAnswer={markedAnswer => setMarkedAnswer(markedAnswer)}
          pointer={pointer}
          chosenanswers={data.avancetest.chosenanswers}
          state={
            data.avancecurso.estao === "iniciado" ||
            data.avancecurso.estao === "activo"
              ? false
              : true
          }
          updateAvancetest={variables => updateAvancetest(variables)}
          avancetest={avancetest}
          localProgress={d => localProgress(d)}
        />
      </Box>

      <Box
        background={constantes("maincolor")}
        height="70px"
        justify="around"
        gap="medium"
        pad="small"
        direction="row"
        fill="horizontal"
        style={{ position: "absolute", left: 0, bottom: 0 }}
      >
        <Button
          disabled={data.avancetest.chosenanswers.length !== 0 ? false : true}
          primary
          onClick={() => previous()}
          style={{ height: 50, borderRadius: 25 }}
          color="white"
          icon={<Previous />}
          label={
            data.avancecurso.estao !== "activo" &&
            data.avancecurso.estao !== "iniciado" &&
            pointer === 0
              ? "Salir"
              : "Anterior"
          }
        />

        {
          <Box
            fill="horizontal"
            style={{
              height: 50,
              width: 200,
              borderRadius: 10,
              cursor: "pointer"
            }}
            color="gray"
            justify="center"
            align="center"
            onClick={() => {
              history.push({
                pathname: "/curso/indice",
                search: `?id=${avancecurso}`
              });
            }}
          >
            <Text color={constantes("maincolorInverted")}>
              Volver al índice
            </Text>
          </Box>
        }

        {data.avancetest.chosenanswers.length + 1 !==
        data.avancetest.exam.preguntas.length ? (
          <Button
            color="white"
            primary
            reverse
            style={{ height: 50, borderRadius: 25 }}
            label={
              data.avancecurso.estao !== "activo" &&
              data.avancecurso.estao !== "iniciado" &&
              pointer + 1 === data.avancetest.chosenanswers.length
                ? "Ver resultados"
                : "Siguiente"
            }
            disabled={
              data.avancecurso.estao !== "activo" &&
              data.avancecurso.estao !== "iniciado"
                ? false
                : Object.keys(markedAnswer)[0] === ""
                ? true
                : false
            }
            onClick={() => next()}
            icon={<Next />}
          />
        ) : (
          <Link
            to={{
              pathname: "/EndSmartEncuesta",
              search:
                "?avancecurso=" + avancecurso + "&avancetest=" + avancetest
            }}
          >
            <Button
              color="white"
              primary
              reverse
              style={{ height: 50, borderRadius: 25 }}
              label="Finalizar"
              onClick={() => end()}
              icon={<Next />}
            />
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default withLocalStorage(withRouter(QuestionSmartEncuesta));
