import gql from "graphql-tag";

export const createComentario = gql`
  mutation(
    $comments: String
    $cursos: ID
    $user: ID
    $publish_date: DateTime
    $webinar: ID
  ) {
    createComentario(
      input: {
        data: {
          comments: $comments
          cursos: $cursos
          webinar: $webinar
          user: $user
          publish_date: $publish_date
        }
      }
    ) {
      comentario {
        _id
      }
    }
  }
`;

export const updateAvancecurso = gql`
  mutation($id: ID!, $avance: JSON, $estao: ENUM_AVANCECURSO_ESTAO) {
    updateAvancecurso(
      input: { where: { id: $id }, data: { avance: $avance, estao: $estao } }
    ) {
      avancecurso {
        _id
      }
    }
  }
`;

export const updateAvancetest = gql`
  mutation($id: ID!, $chosenanswers: JSON, $state: ENUM_AVANCETEST_STATE) {
    updateAvancetest(
      input: {
        where: { id: $id }
        data: { chosenanswers: $chosenanswers, state: $state }
      }
    ) {
      avancetest {
        _id
      }
    }
  }
`;

export const updateUser = gql`
 mutation($id: ID!, $name: String, $surname: String, $dni: String, $email: String, $password: String) {
    updateUser(
      input: {
        where: { id: $id }
        data: {name: $name, surname: $surname, dni: $dni, email: $email, password: $password}
      }
    ) {
      user {
        _id
      }
    }
  }
`