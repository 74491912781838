import React from 'react';
import { Box, Video, Text, Button } from "grommet";
import { useQuery } from "@apollo/react-hooks";

import * as queries from '../graphql/queries';


const RecordingsWebinar = ({ webinar }) => {
    const uri = `/graphql?query=%7B%0A%20%20webinars(where%3A%7B_id%3A%22${webinar}%22%7D)%7B%0A%20%20%20%20_id%0A%20%20%20%20%20%20name%0A%20%20%20%20recordings%0A%20%20%7D%0A%7D`;

    const { loading, error, data, refetch } = useQuery(queries.GetRecordings, {
        variables: {
            id: webinar
        }
    });

    if (loading) return null;
    if (error) {
        console.log(error);
        return null;
    }

    return (
        <Box >
            <Video controls="over" onEnded={() => this.props.unlock()}>
                {data.webinar.recordings.length > 0 && <source
                    key="video"
                    src={data.webinar.recordings[0]}
                    type="video/mp4"
                />}
            </Video>
            <Box>
                <Text alignSelf="center" textAlign="center">
                    {data.webinar.recordings.length > 0 ? "Aqui tienes sus videos" : "Sus videos estaran disponible pronto, actualize para intentarlo de nuevo"}
                </Text>
                {
                    data.webinar.recordings.length === 0 && <Button label="Actualizar"
                        onClick={() => refetch()} />
                }
            </Box>
        </Box>
    )
}

export default RecordingsWebinar;