import React, { Component } from "react";
import { Box, Text, Button, Heading, Meter } from "grommet";
import { Menu, Close, Previous, Next } from "grommet-icons";
import constantes from "../../utils/constantes";
import { Link } from "react-router-dom";
import _ from 'lodash';

export default class QuestionSmart extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      title: "",
      media: [],
      dataTest: [{ _id: "nun", entrada: ["1"], chosenanswers: [] }, 0],
      playpause: false,
      muted: false,
      playbackPosition: "",
      videoLenght: null,
      advanceSlider: 0,
      currentTime: 0,
      progressData: 0,
      error: null,
      replay: false,
      showReplay: false,
      fullscreen: true,
      seek: false,
      offvol: false,
      visible: false,
      arrcoment: [],
      wasplaying: false,
      pregunta: [{ answers: [] }],
      answers: [],
      estao: false,
      modalOpen: false,
      ocultaBarra: false,
      avancecurso: { lookIndex: false }
    };
  }
  preguntaPendiente = () => {
    this.setState({ modalOpen: false });
    this.props.navigation.push("QuestionSMART", {
      data: [
        this.state.dataTest[0],
        this.state.dataTest[0].chosenanswers.findIndex(e => e.value === null),
        this.state.dataTest[2]
      ]
    });
  };
  goEnd = async () => {
    this.setState({
      modalOpen: false
    });
  };
  cambiaPregunta = async position => {
    const token = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await localStorage.getItem("jwt")}`
    };
    const newDataTest = this.state.dataTest;
    newDataTest[1] = position;
    this.setState({ dataTest: newDataTest });
    await fetch(
      `${constantes("URL_API")}/questions/${
      this.state.dataTest[0].chosenanswers[position].id
      }`,
      {
        headers: token
      }
    )
      .then(data => data.json())
      .then(dataJson => {
        this.setState({
          pregunta: dataJson,
          answers: dataJson.answers.sort(function (a, b) {
            return 0.5 - Math.random();
          })
        });
      })
      .catch(error => {
        this.setState({
          error
        });
      });
  };

  async componentDidMount() {
    const { navigation } = this.props;
    const data = this.props.location.state.data;
    const progresoTest = this.props.location.state.progTest
      ? this.props.location.state.progTest
      : 0;

    const ocultaBarra = this.props.location.state.ocultaBarra
      ? this.props.location.state.ocultaBarra
      : false;
    const token = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await localStorage.getItem("jwt")}`
    };
    const progresoTest2 = await fetch(
      `${constantes("URL_API")}/graphql?query=%7B%0A%20%20avancetest(id%3A%22${
      data[0]._id
      }%22)%7B%0A%20%20%20%20chosenanswers%0A%20%20%7D%0A%7D`,
      {
        headers: token
      }
    )
      .then(data => data.json())
      .then(dataJon => {
        return dataJon.data.avancetest.chosenanswers;
      });
    data[0].chosenanswers = progresoTest2;
    this.setState({
      dataTest: { ...data, chosenanswers: progresoTest2 },
      fakeTest: { ...data, chosenanswers: progresoTest2 },
      progressData:
        progresoTest2.filter(e => e.value !== null).length /
        progresoTest2.length,
      ocultaBarra
    });
    await fetch(
      `${constantes("URL_API")}/questions/${data[0].chosenanswers[data[1]].id}`,
      {
        headers: token
      }
    )
      .then(data => data.json())
      .then(dataJson => {
        this.setState({
          pregunta: dataJson,
          answers: dataJson.answers.sort(function (a, b) {
            return 0.5 - Math.random();
          })
        });
      })
      .catch(error => {
        this.setState({
          error
        });
      });
    if (progresoTest === 0) {
      this.setState({
        progressData:
          (progresoTest2.filter(e => e.value !== null).length /
            progresoTest2.length) *
          100
      });
    }
    await fetch(`${constantes("URL_API")}/avancecursos/${data[2]}`, {
      headers: token
    })
      .then(data => data.json())
      .then(dataJson => {
        this.setState({
          avancecurso: dataJson,
          estao:
            dataJson.estao === "iniciado" || dataJson.estao === "activo"
              ? false
              : true
        });
      })
      .catch(error => {
        this.setState({
          error
        });
      });
  }
  resetTest = async () => {
    const a = this.state.dataTest[0];
    const b = this.state.dataTest[1];
    a.chosenanswers.map(e => {
      return (e.value = null), (e.answer = "");
    });
    this.setState({
      dataTest: [a, b]
    });
    fetch(
      `${constantes("URL_API")}/avancetests/${this.state.dataTest[0]._id}`,
      {
        method: "PUT",
        body: JSON.stringify({
          chosenanswers: this.state.dataTest[0].chosenanswers
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${await localStorage.getItem("jwt")}`
        }
      }
    );
    this.props.navigation.push("InicioSMART");
  };

  closeControlPanel = () => {
    this._drawer.close();
  };

  openControlPanel = () => {
    this._drawer.open();
  };

  render() {
    var cuerpoPregunta;

    cuerpoPregunta = (
      <Box>
        {/* ENUNCIADO*/}

        <Heading level={2} tabIndex={0} aria-label={"Pregunta: " + this.state.pregunta.text}>{this.state.pregunta.text}</Heading>

        <Box gap="small" tabIndex={0} aria-label={"Respuestas"}>
          {this.state.answers !== undefined &&
            this.state.answers.map((d, i) => {
              const correcta = this.state.estao === true && d.value === "true"
                ? "correcta"
                : this.state.estao === true &&
                  this.state.dataTest[0].chosenanswers[
                    this.state.dataTest[1]
                  ].answer === d.texto &&
                  d.value === "false"
                  ? "incorrecta"
                  : ""
              const seleccionada = this.state.estao === true && d.value === "true"
                ? ""
                : this.state.estao === true &&
                  this.state.dataTest[0].chosenanswers[
                    this.state.dataTest[1]
                  ].answer === d.texto &&
                  d.value === "false"
                  ? "seleccionada"
                  : this.state.dataTest[0].chosenanswers[
                    this.state.dataTest[1]
                  ].answer === d.texto
                    ? "seleccionada"
                    : ""
              const a11yTitle = `Respuesta ${i + 1} ${seleccionada} ${correcta}: ${d.texto}`;
              return < Button
                fill="horizontal"
                key={i}
                tabIndex={0}
                a11yTitle={a11yTitle}
                label={d.texto}
                style={{
                  height: "auto",
                  minHeight: 50,
                  borderRadius: 25,
                  color:
                    this.state.estao === true && d.value === "true"
                      ? "white"
                      : this.state.markedAnswer === d.texto
                        ? "white"
                        : this.state.dataTest[0].chosenanswers[
                          this.state.dataTest[1]
                        ].answer === d.texto
                          ? "white"
                          : "gray"
                }}
                primary={
                  this.state.estao === true && d.value === "true"
                    ? "#A2DA34"
                    : this.state.markedAnswer === d.texto
                      ? true
                      : this.state.dataTest[0].chosenanswers[
                        this.state.dataTest[1]
                      ].answer === d.texto
                        ? true
                        : false
                }
                color={
                  this.state.estao === true && d.value === "true"
                    ? "#A2DA34"
                    : this.state.estao === true &&
                      this.state.dataTest[0].chosenanswers[
                        this.state.dataTest[1]
                      ].answer === d.texto &&
                      d.value === "false"
                      ? "red"
                      : this.state.dataTest[0].chosenanswers[
                        this.state.dataTest[1]
                      ].answer === d.texto
                        ? constantes("maincolor")
                        : constantes("maincolor")
                }
                onClick={async () => {
                  // console.log(this.state.estao, d.value)
                  if (!this.state.estao) {
                    const a = this.state.dataTest[0];
                    const b = this.state.dataTest[1];
                    const c = this.state.dataTest[2];
                    const token = {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${await localStorage.getItem(
                        "jwt"
                      )}`
                    };
                    a.chosenanswers[b].value = d.value;
                    a.chosenanswers[b].answer = d.texto;
                    this.setState({
                      markedAnswer: d.texto,
                      dataTest: [a, b, c],
                      progressData:
                        (a.chosenanswers.filter(e => e.value !== null).length /
                          a.chosenanswers.length) *
                        100
                    });
                    fetch(
                      `${constantes("URL_API")}/avancetests/${
                      this.state.dataTest[0]._id
                      }`,
                      {
                        method: "PUT",
                        body: JSON.stringify({
                          chosenanswers: this.state.dataTest[0].chosenanswers
                        }),
                        headers: token
                      }
                    );
                  }
                }}
              />
            }
            )}
        </Box>
      </Box>
    );

    // const { goBack } = this.props.navigation;
    const ref = 0;

    return (
      <Box flex="grow" align="center">
        <Box
          fill="horizontal"
          background={constantes("maincolor")}
          height="30px"
        />
        {this.state.ocultaBarra !== true && (
          <div tabIndex={0}
            aria-label="Porcentaje de consecución del test">
            <Meter
              tabIndex={0}
              aria-label={_.round(this.state.progressData, 2) + "%"}
              type="bar"
              size="large"
              thickness="medium"
              round={true}
              background="light-3"
              style={{ marginTop: 60 }}
              values={[
                {
                  value: this.state.progressData,
                  color: "#A2DA34"
                }
              ]}
            />
          </div>
        )}

        <Box justify="center" style={{ maxWidth: 700, minHeight: 450 }}>
          {cuerpoPregunta}
        </Box>
        <Box
          background={constantes("maincolor")}
          height="70px"
          justify="around"
          gap="medium"
          pad="small"
          direction="row"
          fill="horizontal"
          style={{ position: "absolute", left: 0, bottom: 0 }}
        >
          <Button
            disabled={this.state.dataTest[1] !== 0 ? false : true}
            onClick={async () => {
              this.state.dataTest[1] === 0
                ? this.resetTest()
                : this.cambiaPregunta(this.state.dataTest[1] - 1);
            }}
            style={{ height: 50, borderRadius: 25 }}
            primary
            color="white"
            icon={<Previous tabIndex={-1} area-label="" />}
            label="Anterior"
            tabIndex={0}
            a11yTitle="Pregunta Anterior"
          />

          <Button
            style={{
              height: 50,
              borderRadius: 10,
              border: "none",
              cursor: "pointer",
              color: "white"
            }}
            onClick={() => {
              this.props.history.push({
                pathname: "/curso/indice",
                search: `?id=${this.state.dataTest[2]}`
              });
            }}>
            <Text>Volver al índice</Text>
          </Button>

          {this.state.dataTest[1] + 1 <
            this.state.dataTest[0].chosenanswers.length ? (
              <Button
                color="white"
                primary
                reverse
                style={{ height: 50, borderRadius: 25 }}
                label="Siguiente"
                a11yTitle="Siguiente Pregunta"
                tabIndex={0}
                onClick={() => {
                  this.cambiaPregunta(this.state.dataTest[1] + 1);
                }}
                icon={<Next tabIndex={-1} a11yTitle="" />}
              />
            ) : (
              <Link
                to={{
                  pathname: "/end",
                  search: "?id=" + this.state.dataTest[2],
                  state: { progres: this.state.dataTest[0] }
                }}
              >
                <Button
                  color="white"
                  primary
                  reverse
                  style={{ height: 50, borderRadius: 25 }}
                  label="Finalizar"
                  onClick={() => this.goEnd()}
                  a11yTitle="Finalizar Test"
                  tabIndex={0}
                  icon={<Next tabIndex={-1} a11yTitle="" />}
                />
              </Link>
            )}
        </Box>
      </Box>
    );
  }
}
