import React, { useState, useEffect } from "react";
import { Box, Text } from "grommet";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import HeaderImage from "../../components/HeaderImage";
import EditProfile from "../../components/EditProfile";
import Loading from "../../components/Loading";
import constantes from "../../utils/constantes";

const MyProfile = ({ }) => {
  const [erromsg, setErromsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [changePass, setChangePass] = useState(false);
  const [image, setImage] = useState(constantes("placeholder"))
  const id = localStorage.getItem("id");

  const { loading, error, data } = useQuery(queries.GetUserInfo, {
    variables: { id }
  });
  const [updateUser, { loading: loadingMutation }] = useMutation(mutations.updateUser, {
    refetchQueries: [{
      query: queries.GetUserInfo,
      variables: { id }
    }]
  });

  useEffect(() => {
    data && data.user.picture && data.user.picture.url && setImage(data.user.picture.url)
  }, [data])

  return (!loadingMutation && data) ? (
    <Box style={{ flex: 1 }} align="center">
      {erromsg.trim() !== "" && (
        <Box
          animation={["slideDown", "pulse"]}
          background="#C82333"
          round="small"
          border
          style={{ position: "absolute", marginTop: "80px", zIndex: 20 }}
          width="medium"
          align="center"
          role="alert"
          pad="10px"
          onClick={() => setErromsg("")}
        >
          <Text>{erromsg}</Text>
        </Box>
      )}
      {successMsg.trim() !== "" && (
        <Box
          animation={["slideDown", "pulse"]}
          background="#28a745"
          round="small"
          border
          style={{ position: "absolute", marginTop: "80px", zIndex: 20 }}
          width="medium"
          align="center"
          role="alert"
          pad="10px"
          onClick={() => setSuccessMsg("")}
        >
          <Text>{successMsg}</Text>
        </Box>
      )}
      <HeaderImage image={image} setImage={image => setImage(image)} />
      <EditProfile
        data={data}
        updateUser={variables => updateUser(variables)}
        changePass={changePass}
        setChangePass={changePass => setChangePass(changePass)}
        setErromsg={erromsg => setErromsg(erromsg)}
        setSuccessMsg={successMsg => setSuccessMsg(successMsg)} />
    </Box>
  ) : <Loading />;
};

export default MyProfile;

