import React, { Component } from "react";
import { Box, Button, Heading, Image, Text, Meter } from "grommet";
import { BrowserRouter as Router, Link } from "react-router-dom";
// import * as Progress from "react-native-progress";
// import auth from "../utils/auth";
import constantes from "../../utils/constantes";
import _ from "lodash";
import { FormPreviousLink, PlayFill, Checkmark, Play } from "grommet-icons";

class IndexCurso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progressData: 0,
      avatar: "",
      contenidos: { avance: [], cursos: { exam: { name: "" } } },
      avancecurso: [],
      dataCurso: [],
      loader: true,
      numSemana: new Date()
    };
  }

  async componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    let id = params.get("id");

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await localStorage.getItem("jwt")}`
    };
    const contenidos = await (
      await fetch(
        constantes("URL_API") +
          "/graphql?query=%7B%0A%20%20avancecurso(id%3A%22" +
          id +
          "%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20avance%0A%20%20%20%20estao%0A%20%20%20%20cursos%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20nombre%0A%20%20%20%20%20%20entrada%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20title%0A%20%20%20%20%20%20%20%20type%0A%20%20%20%20%20%20%20%20image%0A%20%20%20%20%20%20%7D%20%20%20%20%20%20%0A%20%20%20%20exam%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20type%0A%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D",
        { headers }
      )
    ).json();
    await this.setState({
      idPagina: id,
      contenidos: contenidos.data.avancecurso,
      numSemana: contenidos.data.avancecurso.avance[0].fecha_inicio,
      progressData:
        (contenidos.data.avancecurso.avance.filter(
          e => e.status === "completado"
        ).length /
          contenidos.data.avancecurso.avance.length) *
        100,
      loader: false
    });
  }

  async goTest() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await localStorage.getItem("jwt")}`
    };
    const idCurso = this.state.contenidos.cursos._id;
    const examenID = this.state.contenidos.avance.slice(-1)[0]._id;
    const myid = await localStorage.getItem("id");
    const test = await (
      await fetch(
        constantes("URL_API") +
          "/graphql?query=%7B%0A%20%20exam(id%3A%22" +
          examenID +
          "%22)%7B%0A%20%20%20%20avancetests%20%7B%0A%20%20%20%20_id%0A%20%20%20%20user%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20curso%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A%7D",

        { headers }
      )
    ).json();
    const avancetestID = test.data.exam.avancetests.filter(
      avancetest =>
        avancetest.user !== null &&
        avancetest.user._id === myid &&
        avancetest.curso._id === idCurso
    )[0]._id;
    const avancetest = await (
      await fetch(constantes("URL_API") + "/avancetests/" + avancetestID, {
        headers
      })
    ).json();

    this.state.contenidos.avance.filter(e => e.tipo === "test")[0] &&
    avancetest.exam.type !== "encuesta"
      ? // console.log("no encuesta")
        this.props.history.push({
          pathname: "/examen",
          state: {
            id: this.state.cursoID,
            data: [avancetest, 0, this.state.idPagina],
            ocultaBarra:
              this.state.contenidos.estao === "iniciado" ||
              this.state.contenidos.estao === "activo"
                ? false
                : true
          }
        })
      : // console.log(avancetest.exam.type)
        this.props.history.push({
          pathname: "/examenEncuesta",
          state: {
            id: this.state.cursoID,
            data: [avancetest._id, 0, this.state.idPagina]
          }
        });
  }

  render() {
    return (
      <Box gap="small" flex={true} align="center" tabIndex={-1}>
        <Box
          tabIndex={0}
          aria-label="Cabecera"
          gap="small"
          flex={true}
          align="center"
        >
          <Box
            fill="horizontal"
            background={constantes("mainlogo")}
            pad="small"
            direction="row"
            align="center"
            justify="center"
            style={{ marginTop: 70 }}
          >
            {/* <Link to={{ pathname: "/cursos" }}>
            <Button size="small" icon={<FormPreviousLink />} />
          </Link> */}

            <Heading
              level={2}
              textAlign="center"
              style={{ marginBottom: 55 }}
              tabIndex={0}
            >
              ÍNDICE DE CONTENIDOS
            </Heading>
          </Box>
          {/* INDICADOR DE AVANCE */}
          <Box
            fill="horizontal"
            align="center"
            style={{ marginTop: -25 }}
            background="transparent"
            tabIndex={0}
            aria-label="Porcentaje de consecución del curso"
          >
            <Meter
              type="bar"
              size="large"
              thickness="medium"
              round="true"
              background="light-3"
              tabIndex={0}
              aria-label={_.round(this.state.progressData, 2) + " %"}
              values={[{ value: this.state.progressData, color: "#80BE3B" }]}
            />
          </Box>
        </Box>

        <Box
          flex={true}
          fill="horizontal"
          align="center"
          overflow="auto"
          tabIndex={-1}
        >
          <Box gap="medium" style={{ maxWidth: 700 }} tabIndex={-1}>
            {this.state.contenidos.avance
              .filter(e => e.tipo !== "test")
              .map((avance, i) => (
                <Box key={i} gap="medium" flex="grow">
                  {/* INDICADOR DE Semana */}
                  {i === 0 && (
                    // ? true
                    // : this.state.contenidos.avance[i - 1 === -1 ? 0 : i - 1]
                    //     .fecha_inicio !== avance.fecha_inicio) === true
                    <Heading
                      level={2}
                      tabIndex={0}
                      aria-label="Listado de contenidos"
                    >
                      Contenidos
                      {/* {" "}
                      Semana{" "}
                      {moment(avance.fecha_inicio)
                        .add(1, "day")
                        .diff(moment(this.state.numSemana), "weeks") + 1} */}
                    </Heading>
                  )}
                  {/* FIN INDICADOR DE SEMANA± */}

                  <Link
                    onClick={event =>
                      new Date(this.state.contenidos.avance[i].fecha_inicio) >
                        new Date() ===
                        true && event.preventDefault()
                    }
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: "/contenido",
                      search: `?id=${avance._id}`,
                      state: {
                        id: this.state.contenidos.cursos._id,
                        data: [
                          this.state.contenidos,
                          i,
                          this.state.contenidos._id
                        ]
                      }
                    }}
                    tabIndex={-1}
                  >
                    <Box
                      key={i}
                      elevation="large"
                      gap="small"
                      round="small"
                      responsive={true}
                      background="light-1"
                      width="large"
                      direction="row"
                      height="150px"
                      style={{
                        opacity:
                          new Date(
                            this.state.contenidos.avance[i].fecha_inicio
                          ) >
                            new Date() ===
                          true
                            ? 0.4
                            : 1
                      }}
                      disabled={
                        new Date(this.state.contenidos.avance[i].fecha_inicio) >
                          new Date() ===
                        true
                          ? true
                          : false
                      }
                      tabIndex={0}
                      aria-label={
                        this.state.contenidos.cursos.entrada &&
                        this.state.contenidos.cursos.entrada.find(
                          e => e._id === avance._id
                        ).title
                      }
                    >
                      {this.state.contenidos.cursos.entrada.find(
                        e => e._id === avance._id
                      ) && (
                        <Image
                          tabIndex={0}
                          src={
                            this.state.contenidos.cursos.entrada.find(
                              e => e._id === avance._id
                            ).image !== null
                              ? this.state.contenidos.cursos.entrada.find(
                                  e => e._id === avance._id
                                ).image.url
                              : constantes("placeholder")
                          }
                          alt={
                            this.state.contenidos.cursos.entrada.find(
                              e => e._id === avance._id
                            ).image.alt
                              ? this.state.contenidos.cursos.entrada.find(
                                  e => e._id === avance._id
                                ).image.alt
                              : "Sin descripción de imagen"
                          }
                          style={{ flex: 3, height: 150, borderRadius: 15 }}
                          fit="cover"
                        />
                      )}
                      <Box
                        style={{
                          flex: 5,
                          justifyContent: "center",
                          paddingHorizontal: 15
                        }}
                        tabIndex={-1}
                      >
                        {this.state.contenidos.cursos.entrada.find(
                          e => e._id === avance._id
                        ) && (
                          <Heading level={3} tabIndex={0}>
                            {this.state.contenidos.cursos.entrada &&
                              this.state.contenidos.cursos.entrada.find(
                                e => e._id === avance._id
                              ).title}
                          </Heading>
                        )}
                      </Box>
                      {
                        <Box
                          style={{
                            flex: 2,
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                          tabIndex={-1}
                        >
                          <Checkmark
                            tabIndex={0}
                            aria-label={
                              new Date(
                                this.state.contenidos.avance[i].fecha_inicio
                              ) >
                                new Date() ===
                              true
                                ? "Contenido No Disponible Button"
                                : this.state.contenidos.avance[i].status ===
                                  "completado"
                                ? "Contenido Completado Button"
                                : "Contenido Pendiente Button"
                            }
                            size={"large"}
                            color={
                              new Date(
                                this.state.contenidos.avance[i].fecha_inicio
                              ) >
                                new Date() ===
                              true
                                ? "white"
                                : this.state.contenidos.avance[i].status ===
                                  "completado"
                                ? "green"
                                : "gray"
                            }
                          />
                        </Box>
                      }
                    </Box>
                  </Link>
                </Box>
              ))}
            {
              <Box tabIndex={-1}>
                <Heading level={2} tabIndex={0} aria-label="Bloque Test">
                  {/* {this.state.contenidos.cursos.exam.type === "encuesta"
                    ? "Encuesta"
                    : "Examen"} */}
                  Test
                </Heading>

                {/* CAJA DEL TEST */}
                <Box
                  elevation="large"
                  gap="small"
                  round="small"
                  background="light-1"
                  width="large"
                  responsive={true}
                  direction="row"
                  flex="grow"
                  height="150px"
                  style={{
                    cursor: "pointer",
                    marginBottom: 50,
                    opacity:
                      this.state.contenidos.avance.filter(
                        e => e.obligatorio && e.obligatorio === true
                      ).length !==
                      this.state.contenidos.avance.filter(
                        e =>
                          e.status === "completado" &&
                          e.obligatorio &&
                          e.obligatorio === true
                      ).length
                        ? 0.4
                        : 1
                  }}
                  tabIndex={0}
                  aria-label={this.state.contenidos.cursos.exam.name}
                  // disabled={
                  //   this.state.contenidos.avance.filter(
                  //       e => e.obligatorio&& e.obligatorio === true
                  //     ).length !==
                  //     this.state.contenidos.avance.filter(
                  //       e => e.status === "completado"&&e.obligatorio&& e.obligatorio === true
                  //     ).length
                  //       ? true
                  //       : false
                  // }
                  onClick={() => {
                    this.state.contenidos.avance.filter(
                      e => e.obligatorio && e.obligatorio === true
                    ).length ===
                      this.state.contenidos.avance.filter(
                        e =>
                          e.status === "completado" &&
                          e.obligatorio &&
                          e.obligatorio === true
                      ).length && this.goTest();
                    //this.goTest()
                  }}
                >
                  <Image
                    src={constantes("placeholder")}
                    alt={"Test"}
                    style={{ flex: 3, height: 150, borderRadius: 15 }}
                    fit="cover"
                    tabIndex={-1}
                  />
                  <Box
                    style={{
                      flex: 5,
                      justifyContent: "center",
                      paddingHorizontal: 15
                    }}
                  >
                    <Heading level={3}>
                      {this.state.contenidos.cursos.exam.name}
                    </Heading>
                  </Box>
                  {
                    <Box
                      style={{
                        flex: 2,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <PlayFill
                        size={"large"}
                        tabIndex={0}
                        aria-label="Accerder Button"
                        color={
                          this.state.contenidos.avance.length > 0 &&
                          this.state.contenidos.avance.slice(-1)[0].status ===
                            "completado"
                            ? "green"
                            : "gray"
                        }
                      />
                    </Box>
                  }
                </Box>
              </Box>
            }
            {
              <Box
                fill="horizontal"
                label={"Volver al inicio"}
                style={{
                  height: 50,
                  borderRadius: 10,
                  border: "none",
                  cursor: "pointer"
                  // opacity: this.state.dataCurso[1] === 0 ? 0 : 1
                }}
                justify="center"
                align="center"
                color="gray"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/"
                  });
                }}
              >
                <Text>Volver</Text>
              </Box>
            }
          </Box>
        </Box>
      </Box>
    );
  }
}

export default IndexCurso;
