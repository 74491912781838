import React from "react";
import { Box, Image, Text } from "grommet";
import * as GrommetIcons from "grommet-icons";
import constantes from "../utils/constantes";

const HeaderImage = ({ image, setImage }) => {
  const setNewImage = (newImage) => {
    const jwt = localStorage.getItem("jwt");
    let reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result)
    };
    reader.readAsDataURL(newImage);

    const imgToUpload = new FormData();
    imgToUpload.append('files', newImage);
    imgToUpload.append('refId', localStorage.getItem("id"));
    imgToUpload.append('ref', "user");
    imgToUpload.append('field', "picture");
    imgToUpload.append('source', 'users-permissions')
    fetch(constantes("URL_API") + "/upload", {
      method: 'POST',
      body: imgToUpload,
      headers: {
        'Accept': 'multipart/form-data',
        'Authorization': `Bearer ${jwt}`
      },
    })
  }
  return (
    <Box
      background={constantes("maincolor")}
      role="text"
      aria-label="Cabecera"
      style={{
        elevation: 5,
        marginTop: 70,
        zIndex: 4,
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
      }}
    >
      <Box
        width="160px"
        height="160px"
        background="white"
        elevation="large"
        round="xlarge"
        style={{ marginTop: 0 }}
      >
        <Image
          tabIndex={0}
          src={image}
          style={{ width: 150, height: 150, borderRadius: 75, margin: 5 }}
          fit="cover"
          alt="Imagen de Perfil"
        />
      </Box>
      <Box
        width="large"
        direction="row"
        margin="medium"
        gap="small"
        justify="center"
      >
        <label style={{ backgroundColor: "white", padding: 5, borderRadius: 25 }}>
          <input style={{ display: "none" }} type="file" id="upload_file" className="form-control d-none" accept="image" name="image" id="image" onChange={(event) => setNewImage(event.target.files[0])} />
          <GrommetIcons.Edit
            color={constantes("activecolortext")}
            aria-hidden="true"
            aria-label="Icono cambiar imagén de perfil"
          />
          <Text color={constantes("activecolortext")}>Cambiar Imagén de perfil</Text>
        </label>
      </Box>
    </Box>
  );
};

export default HeaderImage;
