import React, { Component } from "react";
import moment from "moment";
import {
  OTSession,
  OTPublisher,
  OTStreams,
  OTSubscriber,
  createSession
} from "opentok-react";
import Loading from "./Loading";
import constantes from "../utils/constantes";

const OpenTok = require("opentok");
const apiKey = constantes("prodOpenTok").apiKey;
const secret = constantes("prodOpenTok").secret;

export default class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { streams: [], token: null, conectedPeople: [] };
  }

  putWebinar = () => {};

  async componentDidMount() {
    const response = JSON.parse(localStorage.getItem("response"));

    let tokenOptions = {};
    let token;
    //tokenOptions.role = "publisher";
    tokenOptions.role = "subscriber";
    tokenOptions.data = `usernameID=${response.data.users[0]._id}&username=${response.data.users[0].username}`;

    // Generate a token.
    let opentok = new OpenTok(apiKey, secret);

    token = opentok.generateToken(
      response.data.webinars[0].sessionId,
      tokenOptions
    );

    await this.setState({
      response: response,
      token
    });
  }

  render() {
    const eventHandlers = {
      connectionCreated: event => {
        const response = JSON.parse(localStorage.getItem("response"));
        const putData = {
          user: response.data.users[0]._id,
          entrada: moment()
            .utc()
            .format()
        };
        let data = event.connection.data.split("&").map(x => x.split("=")[1]);
        let result = [
          ...this.state.conectedPeople,
          { id: data[0], name: data[1] }
        ];

        this.setState({ conectedPeople: result });
        this.props.setConnectedPeople(result);
      },
      connectionDestroyed: event => {
        const response = JSON.parse(localStorage.getItem("response"));
        const putData = {
          user: response.data.users[0]._id,
          salida: moment()
            .utc()
            .format()
        };
        let data = event.connection.data.split("&").map(x => x.split("=")[1]);

        let idToDelete = data[0];
        let result = this.state.conectedPeople.filter(x => x.id !== idToDelete);

        this.setState({ conectedPeople: result });
        this.props.setConnectedPeople(result);
      }
    };

    return (
      <div style={{ height: "100%" }}>
        {this.state.token !== null ? (
          <OTSession
            style={{ height: "100%" }}
            apiKey={apiKey}
            sessionId={this.state.response.data.webinars[0].sessionId}
            token={this.state.token}
            eventHandlers={eventHandlers}
          >
            <OTStreams style={{ height: "100%" }}>
              <OTSubscriber style={{ height: "100%" }} />
            </OTStreams>
          </OTSession>
        ) : (
          "cargando..."
        )}
      </div>
    );
  }
}
