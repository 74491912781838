import React, { Component } from "react";
import { Box, Button } from "grommet";
import constantes from "../utils/constantes";

const SCREEN_WIDTH = window.innerWidth;

export default class WebinarRecordings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pointer: 0,
      length: 0,
      recordings: [],
      playpause: false,
      muted: false,
      playbackPosition: "",
      videoLenght: null,
      advanceSlider: 0,
      currentTime: 0,
      error: null,
      replay: false,
      showReplay: false,
      fullscreen: true,
      seek: false,
      offvol: false,
      wasplaying: false,
      webiName: "",
      loader: true
    };
  }
  PlayPause = async () => {
    if (!this.state.playPause) {
      this._playbackInstance.setStatusAsync({ shouldPlay: true });
      this.setState({
        playPause: true,
        wasplaying: true
      });
    } else {
      this._playbackInstance.setStatusAsync({ shouldPlay: false });
      this.setState({
        playPause: false,
        wasplaying: false
      });
    }
  };
  muteUnmute = () => {
    if (!this.state.offvol) {
      this.setState({
        offvol: true
      });
      this._playbackInstance.setIsMutedAsync(true);
    } else {
      this.setState({
        offvol: false
      });
      this._playbackInstance.setIsMutedAsync(false);
    }
  };

  replay() {
    this.setState({
      replay: this.state.replay ? false : true
    });
  }

  _seeking() {
    //console.log('hola')
    if (this.state.wasplaying === true) {
      this._playbackInstance.setStatusAsync({ shouldPlay: false });
      this.setState({
        seek: true,
        playPause: false,
        wasplaying: true
      });
    } else {
      this.setState({
        seek: true,
        playPause: false
      });
    }
  }

  _stopseeking() {
    if (this.state.wasplaying === true) {
      this._playbackInstance.setStatusAsync({ shouldPlay: true });
      this.setState({
        playPause: true,
        seek: false,
        advanceSlider: this.state.currentTime,
        wasplaying: true
      });
      this._playbackInstance.setStatusAsync({
        positionMillis: this.state.currentTime
      });
    } else {
      this._playbackInstance.setStatusAsync({ shouldPlay: false });
      this.setState({
        playPause: false,
        seek: false,
        advanceSlider: this.state.currentTime
      });
    }
  }

  _playbackCallback = async playbackStatus => {
    const { navigation } = this.props;
    // console.log(playbackStatus)

    if (!playbackStatus.isLoaded) {
      // Update your UI for the unloaded state

      if (playbackStatus.error) {
        console.log(
          `Encountered a fatal error during playback: ${playbackStatus.error}`
        );
        // Send Expo team the error on Slack or the forums so we can help you debug!
      }
    } else {
      // Update your UI for the loaded state
      this.setState({
        videoLenght: parseFloat(playbackStatus.durationMillis)
      });
      if (playbackStatus.isPlaying) {
        // Update your UI for the playing state
        this.setState({
          advanceSlider: parseFloat(playbackStatus.positionMillis)
        });
      } else {
        // Update your UI for the paused state
      }

      if (playbackStatus.isBuffering) {
        // Update your UI for the buffering state
      }

      if (playbackStatus.didJustFinish && !playbackStatus.isLooping) {
        const id = await navigation.getParam("id", "NO-ID");
        const userID = localStorage.getItem("id");
        const authorization = `Bearer ${localStorage.getItem("jwt")}`;
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authorization
        };
        if (id && userID) {
          fetch(
            constantes("URL_API") +
              "/loganalyticsentradas?entrada=" +
              id +
              "&user=" +
              userID,
            {
              method: "GET",
              headers
            }
          )
            .then(data => data.json())
            .then(async response => {
              // console.log(constantes("URL_API")+"/loganalyticsentrada/"+response[0]._id);
              fetch(
                constantes("URL_API") +
                  "/loganalyticsentradas/" +
                  response[0]._id,
                {
                  method: "PUT",
                  headers,
                  body: JSON.stringify({
                    video_terminado: true
                  })
                }
              );
            });
        }
        // The player has just finished playing and will stop. Maybe you want to play something else?
        this.PlayPause();
        this.replay();
      }

      if (playbackStatus.isLooping) {
      }
    }
  };

  loadData = async () => {
    const uri = `/graphql?query=%7B%0A%20%20webinars(where%3A%7B_id%3A%22${this.props.webinar}%22%7D)%7B%0A%20%20%20%20_id%0A%20%20%20%20%20%20name%0A%20%20%20%20recordings%0A%20%20%7D%0A%7D`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.props.jwt}`
    };
    const queryResponse = await (await fetch(`${constantes("URL_API")}${uri}`, {
      method: "GET",
      headers
    })).json();

    console.log("Asdas");

    this.setState({
      recordings: queryResponse.data.webinars[0].recordings,
      webiName: queryResponse.data.webinars[0].name,
      length: queryResponse.data.webinars[0].recordings.length - 1
    });
  };

  componentDidMount = () => {
    this.loadData();
  };

  retroceso = () => {
    let pointer = this.state.pointer;

    if (pointer - 1 < 0) {
      pointer = this.state.length;
    } else {
      pointer--;
    }

    this.setState({
      pointer
    });
  };

  avance = () => {
    let pointer = this.state.pointer;

    if (pointer + 1 > this.state.length) {
      pointer = 0;
    } else {
      pointer++;
    }

    this.setState({
      pointer
    });
  };

  render() {
    let ret =
      this.state.recordings.length > 0 ? (
        <Box>
          <Box
            style={{
              height: SCREEN_WIDTH * 0.56,
              width: SCREEN_WIDTH,
              backgroundColor: "#ddd",
              elevation: 0
            }}
          >
            {/* <Video
              source={{
                uri:
                  this.state.recordings &&
                  this.state.recordings[this.state.pointer]
              }}
              rate={1.0}
              volume={1.0}
              // isLooping={this.state.replay}
              progressUpdateIntervalMillis={500}
              // resizeMode={Expo.Video.RESIZE_MODE_COVER}
              usePoster={false}
              resizeMode="contain"
              positionMillis={this.state.currentTime}
              style={{ flex: 1 }}
              // ref={component => {
              //   this._playbackInstance = component;
              //   this.ref && ref(component);
              // }}
              onPlaybackStatusUpdate={this._playbackCallback}
            /> */}
          </Box>

          <Box style={{ justifyContent: "center" }}>
            {/* BOTONES VIDEO PLAYER  */}

            <Box
              style={{
                height: 60,
                width: SCREEN_WIDTH,
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "#f2f2f2",
                borderBottomColor: "gainsboro",
                borderBottomWidth: 1
              }}
            >
              <Button onClick={() => this.PlayPause()}>
                <Box
                  style={{
                    marginHorizontal: 10,
                    height: 60,
                    width: 60,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  {/* <Ionicons
                    name={this.state.playPause ? "ios-pause" : "ios-play"}
                    size={35}
                    color="#2BB7EF"
                  /> */}
                </Box>
              </Button>

              {/* <Button
                style={{ width: SCREEN_WIDTH - 90 }}
                onPressIn={() => this._seeking()}
                onPressOut={() => this._stopseeking()}
              >
                <Slider
                  style={{ width: SCREEN_WIDTH - 90 }}
                  maximumValue={this.state.videoLenght}
                  value={this.state.advanceSlider}
                  onValueChange={evt => {
                    console.log(evt);
                    this.setState({ currentTime: evt, seek: true });
                  }}
                />
              </Button> */}
            </Box> 
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            padding: 15,
            position: "relative",
            backgroundColor: "gainsboro"
          }}
        >
          <p style={{ fontSize: 18 }}>
            Aun no se han terminado de subir los videos, intentelo mas tarde
          </p>
          <Button
            onClick={this.loadData}
            style={{
              height: 45,
              width: SCREEN_WIDTH,
              backgroundColor: constantes('maincolor'),
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              bottom: 0
            }}
          >
            <p style={{ color: "white" }}>{"  "}Actualizar</p>
          </Button>
        </Box>
      );

    return ret;
  }
}
