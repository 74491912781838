import React, { Component } from "react";
import { stringify } from "querystring";
import Home from "./Home";
import Loading from "./Loading";
import { SocketProvider } from "socket.io-react";
import constantes from "../utils/constantes";

const uri = new URLSearchParams(window.location.search);

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenPoint: 0,
      screens: ["loading", "error", "app"]
    };
  }

  checkCorrectUSer = response => {
    const webinar = response.data.webinars[0];
    const uri = new URLSearchParams(window.location.search);
    const users = webinar.avwebinars && webinar.avwebinars.map(x => x.user._id);

    if (!users.includes(this.props.user)) {
      return false;
    } else {
      return true;
    }
  };

  componentDidMount() {
    const comparator = ["", null, undefined];

    if (
      !comparator.includes(this.props.user) &&
      !comparator.includes(this.props.webinar) &&
      !comparator.includes(this.props.jwt) &&
      !comparator.includes(this.props.env)
    ) {
      // const uri = `https://apist.cexsolvia.com/graphql?query=%7B%0A%20%20webinars(where%3A%7B_id%3A%22${webinar}%22%7D)%7B%0A%20%20%20%20_id%0A%20%20%20%20sessionId%0A%20%20%20%20name%0A%20%20%20%20participantes%0A%20%20%20%20avwebinars%7B%0A%20%20%20%20%20%20state%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%20%20adjuntos%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20url%0A%20%20%20%20%20%20size%0A%20%20%20%20%20%20mime%0A%20%20%20%20%7D%0A%20%20%20%20%0A%20%20%7D%0A%20%20users(where%3A%20%7Bid%3A%22${user}%22%7D)%7B%0A%20%20%20%20_id%0A%20%20%20%20username%0A%20%20%20%20picture%7B%0A%20%20%20%20%20%20url%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D`;
      const uri = `${constantes(
        "URL_API"
      )}/graphql?query=%0A%7B%0A%20%20webinars(where%3A%7B_id%3A%22${
        this.props.webinar
      }%22%7D)%7B%0A%20%20%20%20_id%0A%20%20%20%20sessionId%0A%20%20%20%20name%0A%20%20%20%20participantes%0A%20%20%20%20userLeader%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20avwebinars%7B%0A%20%20%20%20%20%20state%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%20%20adjuntos%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20url%0A%20%20%20%20%20%20size%0A%20%20%20%20%20%20mime%0A%20%20%20%20%7D%0A%20%20%20%20%0A%20%20%7D%0A%20%20users(where%3A%20%7Bid%3A%22${
        this.props.user
      }%22%7D)%7B%0A%20%20%20%20_id%0A%20%20%20%20username%0A%20%20%20%20picture%7B%0A%20%20%20%20%20%20url%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A`;
      const authorization = `Bearer ${this.props.jwt}`;
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authorization
      };
      fetch(uri, {
        method: "GET",
        headers
      })
        .then(data => data.json())
        .then(response => {
          if (response.errors) {
            response.errors.map(error => {
              if (error.extensions.code === "INTERNAL_SERVER_ERROR") {
                this.setState({
                  errorText: error.message,
                  screenPoint: 1
                });
              }
            });
          } else {
            if (this.checkCorrectUSer(response)) {
              localStorage.setItem("response", JSON.stringify(response));
              localStorage.setItem("jwt", this.props.jwt);
              this.setState({
                screenPoint: 2
              });
            } else {
              this.setState({
                errorText: "El usuario no forma parte de este webinar",
                screenPoint: 1
              });
            }
          }
        })
        .catch(e => {
          console.log(e);
          this.setState({ screenPoint: 1 });
        });
    } else {
      this.setState({
        screenPoint: 1
      });
    }
  }

  render() {
    return (
      <SocketProvider>
        {this.state.screens[this.state.screenPoint] === "loading" ? (
          <Loading />
        ) : this.state.screens[this.state.screenPoint] === "app" ? (
          <Home
            webinar={this.props.webinar}
            jwt={this.props.jwt}
            user={this.props.user}
          />
        ) : (
          JSON.stringify(this.state.errorText)
        )}
      </SocketProvider>
    );
  }
}
