import React, { Component } from "react";
import { Box, Text, Image, Button, Heading } from "grommet";
import constantes from "../utils/constantes";

class RetoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suscrito: this.props.post.avs
        ? this.props.post.avs.filter(z => z.user === this.props.userID)
        : [],
      ok: false
    };
  }
  // componentDidUpdate (prevProps, prevState){
  //     prevProps.media!=
  // }
  componentDidMount() { 
    console.log(this.props.post.avs)   
    if (this.state.suscrito.length > 0) {
      this.props.unlock();      
    }
  }

  // render() {
  //   return "AH";
  // }
  render() {
    // const RetoComponent = ({ this.props.excerpt = "", this.props.media="", this.props.titulo="", this.props.toggleModal, this.props.userID="", this.props.retoOk=false, this.props.handleNext }) => {
    const pic = this.props.media.picture
      ? this.props.media.picture.url
      : constantes("placeholder");
    // console.log(this.props.media)
    const suscrito = this.props.media.avs
      ? this.props.media.avs.filter(z => z.user === this.props.userID)
      : [];
    return (
      <Box gap="small" pad="small">
        <Box height="small" background={`url(${pic})`} round="small" />

        <Box direction="row" gap="small" >

          <Box fill="horizontal" pad="small" round="small" align="center" background="#f2f2f2">
            <Text>Duración del reto (días)</Text>
            <Heading level={2} margin="xsmall">{this.props.media.amount === undefined ? 0 : this.props.media.amount * this.props.media.often}</Heading>
          </Box>

          <Box fill="horizontal" pad="small" round="small" align="center" background="#f2f2f2">
            <Text>Frecuencia alarmas (días)</Text>
            <Heading level={2} margin="xsmall">{this.props.media.often === undefined ? 0 : this.props.media.often}</Heading>
          </Box>
        </Box>

        <Box height="xsmall" align="center" justify="center">
          <Text size="medium">{this.props.media.description}</Text>
        </Box>

        <Box align="center">
          {this.props.post &&
            this.props.post.avs.filter(x => x.user === this.props.userID)
              .length <= 0 ? (
              <Button 
                label="Suscribirse" 
                primary color="#80BE3B" 
                style={{color:'white', width:'fit-content'}}
                onClick={() => {
                  this.props.unlock();
                  this.props.toggleModal()}}/>
            ) : (
              <Box background="#64C500" round="large" margin="medium" align="center" pad="small">
              
                <Text color="white" size="medium">
                  Ya estas suscrito al reto, puedes continuar
              </Text>
              </Box>
            )}
          {/* {this.props.retoOk ? (
            <Box
              style={{
                width: 200,
                height: 45,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#64C500",
                borderRadius: 25
              }}
            >
              <Text style={{ color: "white", fontSize: 14 }}>Completado</Text>
            </Box>
          ) : suscrito.length <= 0 ? (
            <Button onClick={() => this.props.toggleModal()}>
              <Box
                style={{
                  width: 200,
                  height: 45,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#64C500",
                  borderRadius: 25
                }}
              >
                <Text style={{ color: "white", fontSize: 14 }}>
                  Suscribirse
                </Text>
              </Box>
            </Button>
          ) : (
            <Box
              style={{
                width: 300,
                height: 45,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#64C500",
                borderRadius: 25
              }}
            >
              <Text style={{ color: "white", fontSize: 14 }}>
                Ya estas suscrito al reto, puede continuar
              </Text>
            </Box>
          )} */}
        </Box>

        {/* <Box
          style={[{ flex: 1, backgroundColor: "white", alignItems: "center" }]}
        >
          <Text
            style={{
              fontSize: 24,
              fontFamily: "AvenirNextDemiBold",
              marginTop: 30
            }}
          >
            {this.props.titulo}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontFamily: "AvenirNextDemiBold",
              marginTop: 10
            }}
          >
            {this.props.excerpt}
          </Text>
        </Box> */}
      </Box>
    );
  }
}

export default RetoComponent;
