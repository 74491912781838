const constantes = param => {
  //  API DEMOS HUB7S CONFIG //

  const URL_APP_PRO = "https://smartlearning.hub7s.com/forgotpassword/";
  const URL_APP_DEV = "http://localhost:3000/forgotpassword/";
  const URL_API_NEW_PRE = "http://18.202.169.123:1339";
  const URL_API_DEMO = "https://onceapi.hub7s.com";
  //const URL_API_NEW_PRE = "http://34.251.228.205:1338";

  //const Webinar_view = "http://webinarview.s3-website-eu-west-1.amazonaws.com";
  // API DE FOCUS
  //const URL_API_PRO = "https://api.cexsolvia.com";

  const mainlogo =
    "https://training-solvia.s3.eu-west-1.amazonaws.com/fd52aa28c4e64a57ab27bca7ef28140f.png";
  const placeholder =
    "https://training-solvia.s3.eu-west-1.amazonaws.com/b99009ffaebe4fc8a0a2d57beb1d7bbb.png";
  const smalllogo =
    "https://training-solvia.s3.eu-west-1.amazonaws.com/ea6fcac7d8154728a7b2fbf7efc074d2.png";
  const splash = "";

  const maincolor = "black";
  const activecolor = "white";

  // const maincolor = "red";
  // const activecolor = "blue";

  const maincolortext = "white";
  const activecolortext = "black";

  const maincolorInverted = "white";
  // const maincolor = "black";
  const secondarycolor = "#17A3DC";
  const loginbackground = "linear-gradient(-135deg,  black, black)";
  // const loginbackground = "linear-gradient(-135deg, #353535,#000000)";
  const defaultTextSize = "16px";
  const titleTextSize = "30px";

  const prodOpenTok = {
    apiKey: "46328782",
    secret: "3a5eab55576070a32ebdd00d102c140596a92d9b"
  };

  let data = {
    // Current active URLs
    URL_APP: URL_APP_DEV,
    URL_API: URL_API_DEMO,
    //Webinar_view,
    prodOpenTok,

    area_admin_content: "content",

    mainlogo,
    smalllogo,
    splash: "",
    maincolor,
    secondarycolor,
    loginbackground,
    placeholder,
    defaultTextSize,
    titleTextSize,
    maincolorInverted,
    activecolor,
    maincolortext,
    activecolortext
  };
  return data[param];
};

export default constantes;
