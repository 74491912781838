import React, { Component } from "react";
import { Box, Text, Video, Heading } from "grommet";
import renderHTML from "react-render-html";

// import constantes from "../utils/constantes";
// import auth from "../utils/auth";

class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playpause: false,
      muted: false,
      playbackPosition: "",
      videoLenght: 0,
      advanceSlider: 0,
      currentTime: 0,
      error: null,
      replay: false,
      showReplay: false,
      fullscreen: true,
      seek: false,
      offvol: false,
      wasplaying: false
    };
  }


  render() {
    return (
      <Box>
        <Box >
          <Video controls="over" onEnded={() => this.props.unlock()}>
            <source
              key="video"
              src={this.props.media && this.props.media.url}
              type="video/mp4"
            />
          </Video>
        </Box>

        <Box style={{ paddingHorizontal: 15, backgroundColor: "white" }}>
          <Heading level={2} tabIndex={0} aria-label={this.props.titulo}>
            {this.props.titulo}
          </Heading>

          {this.props.contenido !== "" && <div tabIndex={0} aria-label={this.props.contenido.replace(/<[^>]+>/g, '')}>
            {renderHTML(this.props.contenido)}
          </div>}

          {/* {this.props.obligatorio === true && (
            <Text
              style={{ fontSize: 16, marginBottom: 20, textAlign: "center" }}
            >
              Debes visualizar el contenido completo para poder continuar
              </Text>
          )} */}
        </Box>
      </Box>
    );
  }
}

export default VideoComponent;