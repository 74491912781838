import React, { Component } from "react";
import "./loading.css";
import ReactLoading from "react-loading";

class Loading extends Component {
  render() {
    return (
      <div className="load">
        <ReactLoading type="spin" color="black" height={70} width={70} />
      </div>
    );
  }
}

export default Loading;
