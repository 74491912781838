import React, { useState } from "react";
import { Collapsible, Box, Image, Button } from "grommet";
import * as GrommetIcons from "grommet-icons";
import { BrowserRouter as Link, withRouter, Router } from "react-router-dom";
import constantes from "../utils/constantes";

const Menu = ({ history }) => {
  const SignOut = async () => {
    await localStorage.clear();
    history.push("/login");
  };
  return history.location.pathname !== "/login" ? (
    <header>
      <Collapsible
        open={
          history.location.pathname === "/login" ||
          history.location.pathname.includes("forgotpassword")
            ? false
            : true
        }
      >
        <Box
          role="text"
          aria-label="Menú"
          basis="full"
          direction="row"
          fill="horizontal"
          style={{
            zIndex: 30,
            position: "absolute",
            top: 0,
            background: constantes("maincolor")
          }}
        >
          <Box
            tabIndex={-1}
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/")}
            width="small"
            height="70px"
            alignSelf="start"
          >
            <Image
              tabIndex={0}
              fit="contain"
              alt="Logo Hub7, botón inicio"
              src="https://training-solvia.s3.eu-west-1.amazonaws.com/6dc5472ebe644b34874963b1805108ad.png"
            />
          </Box>

          <Box
            tabIndex={-1}
            width="medium"
            flex={{ grow: 7 }}
            style={{ paddingRight: 20 }}
            alignSelf="center"
            justify="end"
            gap="small"
            direction="row"
          >
            {/* history.location.pathname === "/"
                    ? constantes("maincolor")
                    : "white" */}
            <Button
              onClick={() => history.push("/")}
              primary
              style={{
                color:
                  history.location.pathname === "/"
                    ? constantes("maincolor")
                    : "white",
                borderColor:
                  history.location.pathname === "/"
                    ? constantes("maincolor")
                    : "white"
              }}
              color={
                history.location.pathname === "/"
                  ? "white"
                  : constantes("maincolor")
              }
              label="Cursos"
              reverse
              icon={
                <GrommetIcons.Catalog
                  color={
                    history.location.pathname === "/"
                      ? constantes("maincolor")
                      : "white"
                  }
                  aria-hidden="true"
                  aria-label="Icono Cursos"
                />
              }
            />
            <Button
              onClick={() => history.push("/profile")}
              primary
              style={{
                color:
                  history.location.pathname === "/profile"
                    ? constantes("maincolor")
                    : "white",
                borderColor:
                  history.location.pathname === "/profile"
                    ? constantes("maincolor")
                    : "white"
              }}
              color={
                history.location.pathname === "/profile"
                  ? "white"
                  : constantes("maincolor")
              }
              label="Mi Perfil"
              reverse
              icon={
                <GrommetIcons.User
                  color={
                    history.location.pathname === "/profile"
                      ? constantes("maincolor")
                      : "white"
                  }
                  aria-hidden="true"
                  aria-label="Icono Mi Perfil"
                />
              }
            />
            <Button
              onClick={() => SignOut()}
              style={{ color: "white" }}
              color="white"
              label="Salir"
              reverse
              icon={
                <GrommetIcons.Logout
                  color="white"
                  aria-hidden="true"
                  aria-label="Icono Salir"
                />
              }
            />
          </Box>
        </Box>
      </Collapsible>
    </header>
  ) : null;
};

export default withRouter(Menu);
