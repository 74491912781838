//
// ESTE HIGHER ORDER COMPONENT HACE DE MIDDLEWARE PARA POODER PASAR ASINCROONAMENTE
// EL ID DEL USUARIO GUARDADO EN EL ASYNCSTORAGE
//

import React, { Component } from "react";

export default function withLocalStorage(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        jwt: "",
        id: "",
        cache: [],
        filtro: []
      };
      // console.log(props)
      this.getjwt();
    }

    async getjwt() {
      this.setState({
        id: await localStorage.getItem("id"),
        jwt: await localStorage.getItem("jwt")
      });
    }

    render() {
      if (this.state.id === "") {
        return null;
      }
      return <WrappedComponent {...this.props} id={this.state.id} jwt={this.state.jwt} />;
    }
  };
}
