import React, { Component } from "react";
import { Box } from "grommet";
import VideoComponentWeb from "./VideoComponentWeb";
import Loading from "./Loading";

const SCREEN_HEIGHT = window.innerHeight;

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      response: {},
      participantes: [],
      sidebarOpen: false,
      connectedPeople: []
    };
  }

  componentDidMount() {
    const response = localStorage.getItem("response");
    this.setState({
      loading: false,
      response: JSON.parse(response)
    });
  }
  switchDoc = () => {
    this.setState({
      switchDoc: !this.state.switchDoc
    });
  };

  onSetSidebarOpen = open => {
    this.setState({ sidebarOpen: open });
  };

  setConnectedPeople = connectedPeople => {
    this.setState({ connectedPeople });
  };

  render() {
    if (!this.state.loading) {
      const participantes = this.state.participantes.size
        ? this.state.participantes.size
        : 0;
      return this.state.loading ? (
        "loading"
      ) : (
        <Box flex="grow" className="maniga">
          <VideoComponentWeb setConnectedPeople={this.setConnectedPeople} />
        </Box>
      );
    } else {
      return <Loading />;
    }
  }
}
